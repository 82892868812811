.action-card-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    max-width: 245px;
    margin: 0 auto;
}

.action-card-title--discount {
    font-family: 'Raleway Black', sans-serif;
    font-weight: 900;
    font-size: 55px;
    line-height: 65px;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 100%;
}

.action-card-title--text {
    font-family: 'Raleway Black', sans-serif;
    font-weight: 900;
    font-size: 25px;
    line-height: 125%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    text-transform: uppercase;
}


.action-card-title--link {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
}


.actions-holder {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
    flex-direction: column;
}

.action-card {
    height: 320px;
    background: #92B759;
    border-radius: 10px;
    width: 100%;
    transition: box-shadow .3s;
}

.action-card.pointer:hover {
    box-shadow: 4px 4px 4px 0 #FFFFFF4D, 0 5px 8px 0 #00000033;
}

.action-card {
    display: flex;
}

.action-card-image {
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
}

.action-card-image.left {
    border-radius:  10px 0 0 10px;
    max-width: 265px;
    min-width: 250px;
}

.action-card-image.right {
    border-radius: 0 10px 10px 0;
    max-width: 265px;
    min-width: 213px;
}

.actions-row {
    display: flex;
}

.actions-row + .actions-row {
    margin-top: 65px;
}

.actions-row:nth-child(1) .action-card:nth-child(1) {
    max-width: calc((100% / 12 * 4) - 15px);
}

.actions-row:nth-child(1) .action-card:nth-child(2) {
    max-width: calc((100% / 12 * 6) - 50px);
    margin-right: 25px;
    margin-left: 20px;
}

.actions-row:nth-child(1) .action-card:nth-child(3) {
    max-width: calc((100% / 12 * 3) - 20px);
}

.actions-row:nth-child(2) .action-card:nth-child(1) {
    max-width: calc((100% / 12 * 7) - 10px);
    margin-right: 25px;
}

.actions-row:nth-child(2) .action-card:nth-child(2) {
    max-width: calc((100% / 12 * 5) - 15px);
}

.action-modal__button .button,
.action-modal-sheet__button {
    text-transform: uppercase;
    font-weight: bold;
}
