.restaurant-slider-selector {
    position: absolute;
    right: 62px;
    bottom: 233px;
    z-index: 10;
}

.restaurant-slider {
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
}

.restaurant-slider .slick-slider, .restaurant-slider .slick-list, .restaurant-slider .slick-track {
    height: 710px;
}

.restaurant-slider__holder {
    position: relative;
    z-index: 2;
}

.restaurant-slider__overlay {
    background-image: url("./../../../static/green2.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    cursor: pointer;
    position: absolute;
    z-index: 20;
    bottom: 0;
    width: 580px;
    height: 334px;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;

    margin-bottom: 2px; /* WTF? */
}

.restraurant-slider__item {
    width: 312px;
    height: 236px;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
}

.restaurants-list__restaurant {
    cursor: pointer;
}

.slick-center .restraurant-slider__item {
    width: 100%;
    height: 710px;
    min-width: 1156px;
    /*animation: scale-up-center 0.5s ease;*/
}

.restaurant-slider .slick-list {
    padding: 0 0 !important;
    width: 100%;
}

.restaurant-slider .slick-slider .slick-track {
    display: flex;
    align-items: center;
}

.restaurant-slider-arrows {
    width: 100%;
    max-width: 1156px;
    display: flex;
    justify-content: center;
    margin-top: 42px;
}

.restaurant-slider .slider-arrow {
    margin: 0 50px;
    cursor: pointer;
}

.restaurant-slider__description {
    margin-left: 75px;
    color: #fff;
    margin-bottom: 53px;
}

.restaurant-slider__description-text {
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    margin: 20px 0;
}

.restaurant-slider__description-address {
    font-size: 17px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin: 20px 0;
}

.restaurant-slider__description-link {
    font-size: 17px;
    line-height: 23px;
    text-decoration-line: underline;
    color: #FFFFFF;
    margin: 20px 0;
}

.restaurant-slider .slick-slide {
    height: initial;
}

.restaurant-images__slider {
    display: flex;
    align-items: center;
    height: 473px;
}

.restaurant-images__slider .slide {
    transition: all .6s;
}

.restaurant-images__slider .slide + .slide {
    margin-left: 25px;
}

.restaurant-images__slider-item {
    height: 236px;
    width: 312px;
    border-radius: 10px;
    transition: all 0.3s;
    background-size: cover;
    background-position: center;
}

.restaurant-images__slider .slide.slide-active {
    margin-right: 40px;
}

.restaurant-images__slider .slide-active .restaurant-images__slider-item {
    width: 717px;
    height: 473px;
}


.restaurant-images__arrows {
    display: flex;
    width: 200px;
    justify-content: space-between;
    margin: 90px auto 0;
}

.restaurant-images__arrow {
    cursor: pointer;
}

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.restaurant-page__description {
    width: 550px;
    align-self: center;
    padding: 50px;
    background: #88B04B;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2), 4px 4px 4px rgba(255, 255, 255, 0.3);
    border-radius: 10px;
}

.restaurants-page__description {
    width: 550px;
    align-self: center;
    padding: 50px;
    background: #FFFFFF;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2), 4px 4px 4px rgba(255, 255, 255, 0.3);
    border-radius: 10px;
}

.restaurants-page__description .text {
    margin-top: 20px;
}

.restaurants-page__description .button, .restaurant-page__description .button {
    margin-top: 20px;
    width: 215px;
}

.restaurant-page__description .text {
    margin-top: 20px;
    color: #fff;
}

.restaurants-page__holder, .restaurant-page__holder {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.restaurants-page__description, .restaurant-page__description {
    position: absolute;
    left: 0;
    z-index: 2;
}

.restaurants-page__buttons {
    display: flex;
}

.restaurants-page__buttons .button + .button {
    margin-left: 20px;
}

.restaurants-page__image, .restaurant-page__image {
    z-index: 1;
    max-width: 744px;
}

.restaurants-page__image img,
.restaurant-page__image img {
    width: 100%;
    border-radius: 5px;
}

.restaurants-page__map {
    height: 550px;
    margin-top: 150px;
}

.restaurants-page__map--holder {
    height: 100%;
    overflow: hidden;
}

.restaurants-page__map iframe {
    border: none;
    height: calc(100% + 150px);
    width: 100%;
    margin-top: -55px;
    pointer-events: none;
}

.restaurants-page__list.container {
    margin-top: 150px;
}
.restaurants-list {
    grid-gap: 90px 25px;
    display: grid;
    grid-template-columns: 424px 424px 424px;
}

.restaurants-list__restaurant--image {
    width: 424px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 231px;
    border-radius: 5px;
}

.restaurants-list__restaurant-info {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.restaurants-list__restaurant--title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
}

.restaurants-list__restaurant--address {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
}

.restaurant-page__slider {
    margin-top: 240px;
}

.restaurant-page__slider .title {
    margin-bottom: 70px;
}

.container.restaurant-page__actions {
    margin-top: 170px;
}

.container.restaurant-page__actions .title {
    margin-bottom: 70px;
}

.container.restaurant-page__list {
    margin-top: 165px;
}


.container.restaurant-page__list .title {
    margin-bottom: 70px;
}
