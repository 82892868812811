.inner {
    margin: 25px;
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15), 0 7px 16px rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    overflow-y: scroll;
    max-height: 50vh;
}

.title {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #88B04B;
    margin-bottom: 5px;
}

.block {
    display: grid;
    grid-template-columns: 170px 1fr;
    align-items: flex-start;
    margin-bottom: 25px;
}

.col1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
}

.col2 {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    font-size: 14px;
}

.row strong {
    margin-right: 10px;
}
.button {
    width: 330px;
    margin: 0 auto;
}
.button span{
    font-size: 14px;
}

@media (max-width: 768px) {
    .inner {
        margin: 25px;
        padding: 0;
        max-height: 100%;
        box-shadow: unset;
    }

    .button {
        width: 300px;
        margin: 0 auto;
    }
    .button span{
        font-size: 12px;
    }

    .block {
        display: grid;
        grid-template-columns: 110px 1fr;
    }

}