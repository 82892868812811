.container.hits {
    margin-top: 295px;
}

.container.catalog {
    margin-top: 107px;
    margin-bottom: 170px;
    min-height: 500px;
    animation: reveal-animation--one 0.3s linear ;
}


.container.main-banner {
    animation: reveal-animation--one 0.3s ease ;
}

.container.hits .product-cards-slider {
    margin-bottom: 125px;
}

.container.hits .product-cards-slider .product-card {
    min-width: 423px;
}

.container.hits .hits-link a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-decoration: none;
}

.container.hits .hits-link a .text {
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
}

.container.hits .hits-link .arrow {
    margin-left: 20px;
}

.container.actions {
    margin-bottom: 165px;
    animation: reveal-animation--one 0.3s ease ;
}

.container.actions .title {
    padding: 30px 0;
}

.container.restaurants {
    margin-bottom: 40px;
    animation: reveal-animation--one 0.3s ease ;
}

.container.restaurants .title {
    padding: 30px 0;
}

.container.testimonials {
    margin-top: 240px;
    display: flex;
    animation: reveal-animation--one 0.3s ease ;
}

.container-fluid.about {
    background: #92B759;
    margin: 335px 0 0;
}

.container.faq {
    margin-top: 495px;
    margin-bottom: 230px;
    animation: reveal-animation--one 0.3s ease ;
}
