@font-face {
    font-family: 'HitchHike';
    src: local('Hitch-Hike'), local('HitchHike'), local('Hitch Hike'),
    url('../../fonts/Hitch-hike.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway Black';
    src: local('Raleway Black'), local('Raleway-Black'),
    url('../../fonts/Raleway-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: local('Raleway'), local('Raleway-Regular'),
    url('../../fonts/Raleway-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: local('Raleway Italic'), local('Raleway-Italic'),
    url('../../fonts/Raleway-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: local('Raleway Medium'), local('Raleway-Medium'),
    url('../../fonts/Raleway-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
    url('../../fonts/Raleway-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: local('Raleway Bold'), local('Raleway-Bold'),
    url('../../fonts/Raleway-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
