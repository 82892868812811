:root {
  --alt-color: #88B04B;
}

*,
::after,
::before {
  box-sizing: border-box;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.containerLanding {
  width: 1440px;
  margin: 0 auto;
}

.textColorGreen {
  color: var(--alt-color);
}

/* intro */
.intro {
  margin-top: -165px;
}

.introTitle {
  font-size: 33px;
  margin: 0;
  margin-left: 24px;
  margin-right: 24px;
}

.introTitle .textColorGreen br {
  display: none;
}

.introTitleBig {
  font-family: 'Raleway Black', sans-serif;
  font-size: 60px;
  font-weight: 900;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.introContainer {
  display: flex;
  align-items: flex-end;
  padding: 78px 60px;
  padding-left: 269px;
}

.introContainerSlogan {
  display: flex;
  align-items: center;
}

.introImgSlogan,
.introImg {
  flex-shrink: 0;
}

.introImgSlogan img,
.introImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.introImgSlogan {
  height: 180px;
}

/* location */
.location {
  background-color: #fff;
}

.locationQuote {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 40px 40px 0 0;
  background-color: rgba(255, 255, 255, 0.75);
}

.locationQuoteText {
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  margin: 0;
  margin-bottom: 29px;
}

.locationQuoteCite {
  margin-left: auto;
  text-align: right;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.8px;
}

.locationInfoTitle {
  font-family: 'Raleway Black', sans-serif;
  font-size: 44px;
  font-weight: 900;
  letter-spacing: 2.2px;
  text-transform: uppercase;
  max-width: 424px;
  margin-top: 0;
}

.locationInfoText {
  font-size: 24px;
  font-weight: 600;
  margin-top: 0;
}

.locationInfoMainVideoTitle {
  font-family: 'Raleway Black', sans-serif;
  font-size: 36px;
  font-weight: 900;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  margin-top: 0;
}

.locationInfoMainVideoText {
  font-size: 24px;
  font-weight: 600;
}

.locationInfoMainImageQuoteText {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin-top: 0;
}

.locationInfoMainImageQuoteCite {
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  margin-bottom: 0;
}

.locationImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.locationImg {
  position: relative;
}

.locationQuote {
  position: absolute;
  width: 522px;
  min-height: 232px;
  text-align: center;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  margin: 0;
  padding: 31px;
}

.locationInfoHeader {
  display: flex;
  margin-bottom: 50px;
}

.locationInfoTextContainer {
  max-width: 619px;
  margin-left: auto;
}

.locationInfoMain {
  display: flex;
}

.locationInfoMainVideo {
  max-width: 648px;
  padding-top: 74px;
}

.locationContainer {
  padding: 30px 60px 61px 172px;
}

.locationInfoMainImage {
  max-width: 424px;
  margin-left: auto;
  position: relative;
}

.locationInfoMainImageQuote {
  position: absolute;
  top: 13px;
  left: 15px;
  width: 394px;
  margin: 0;
  padding: 14px 10px 29px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.75);
  background-image: url("../../static/images/landings/kimchi/location-arrow-down.svg");
  background-repeat: no-repeat;
  background-position: 94% 75%;
  cursor: pointer;
}

.locationInfoMainImageQuoteIconUp {
  background-image: url("../../static/images/landings/kimchi/location-arrow-up.svg");
  background-position: 94% 93%;
  padding-bottom: 54px;
}

.locationInfoMainImageQuoteCiteWrapper {
  text-align: right;
}

.locationInfoMainVideoItem iframe {
  aspect-ratio: 16/9;
  border-radius: 20px;
}

/* video */
.video {
  background-color: #fff;
}

.videoContainer {
  position: relative;
  padding-right: 60px;
}

.videoItem {
  position: absolute;
  top: 87px;
  left: 60px;
  z-index: 2;
}

.videoItem iframe {
  border-radius: 20px;
}

/* iconList */
.iconList {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.iconListTitle {
  position: relative;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 1.8px;
  margin: 0;
  margin-bottom: 50px;
  padding-left: 110px;
}

.iconListTitle::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: left center;
}

.iconListTitleIcon1::before {
  left: -12px;
  top: -20px;
  width: 116px;
  height: 122px;
  background-image: url("../../static/images/landings/kimchi/icon-1-desktop.png");
}

.iconListTitleIcon2::before {
  top: 3px;
  width: 83px;
  height: 83px;
  background-image: url("../../static/images/landings/kimchi/icon-2-desktop.png");
}

.iconListTitleIcon3::before {
  top: -20px;
  width: 82px;
  height: 82px;
  background-image: url("../../static/images/landings/kimchi/icon-3-desktop.png");
}

.iconListTitleIcon4::before {
  top: -22px;
  width: 82px;
  height: 82px;
  background-image: url("../../static/images/landings/kimchi/icon-4-desktop.png");
}

.iconListText {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.iconListTextWhite .iconListTitle,
.iconListTextWhite .iconListText {
  color: #fff;
}

.iconListTextWhite .iconListTitle {
  margin-bottom: 29px;
}

.iconListItem {
  width: 400px;
}

.iconListItem2 {
  width: 383px;
}

.iconListItem3 {
  width: 336px;
}

/* why */
.why {
  position: relative;
  background-color:  var(--alt-color);
  border-radius: 0 20px 20px 0;
  z-index: 1;
}

.whyContent {
  padding: 87px 40px 36px 508px;
}

.why::before {
  content: "";
  position: absolute;
  background-image: url("../../static/images/landings/kimchi/why-bg-d.svg");
  background-repeat: no-repeat;
  background-color: #fff;
  background-size: cover;
  left: -285px;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.whyTitle {
  font-family: 'Raleway Black', sans-serif;
  color: #FFF;
  font-size: 41px;
  font-weight: 900;
  letter-spacing: 2.05px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 68px;
}

/* benefit */
.benefit {
  padding: 47px 23px 119px 508px;
}

.benefitTitle {
  font-family: 'Raleway Black', sans-serif;
  font-size: 41px;
  font-weight: 900;
  letter-spacing: 2.05px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 70px;
}

/* info */
.info {
  background-color: #fff;
}

.infoTitle {
  font-family: 'Raleway Black', sans-serif;
  font-size: 44px;
  font-weight: 900;
  letter-spacing: 2.2px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 45px;
}

.infoText {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}

.infoListWrapper {
  background-color: var(--alt-color);
}

.infoList {
  padding: 0;
  margin: 0;
  list-style: none;
  padding: 78px 60px;
}

.infoItemText {
  font-size: 24px;
  font-weight: 600;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.75);
  max-width: 615px;
  min-height: 180px;
  margin: 0;
  padding: 20px 34px 16px 79px;
  transform: translateX(-50px);
}

.infoHeaderContainer {
  padding: 0 60px;
  margin-bottom: 68px;
}

.infoImg img,
.infoItemImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.infoHeader {
  display: flex;
  align-items: center;
}

.infoImg {
  flex-shrink: 0;
}

.infoHeaderTextContent {
  margin-left: 24px;
}

.infoItem {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
}

.infoItem:nth-child(2n) .infoItemText {
  order: -1;
  padding: 33px 48px 23px 28px;
  transform: translateX(105px);
}

.infoItem:nth-child(2n) .infoItemImg {
  margin-left: auto;
}

/* autumn */
.autumn {
  background-color: #fff;
}

.autumnContainer {
  padding: 83px 60px 63px;
}

.autumnHeader {
  display: flex;
  margin-bottom: 75px;
}

.autumnTitle {
  flex-shrink: 0;
  font-family: 'Raleway Black', sans-serif;
  font-size: 40px;
  font-weight: 900;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0;
  margin-right: 60px;
}

.autumnTitle br {
  display: none;
}

.autumnText {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.autumnList {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  column-gap: 24px;
}

.autumnItemText {
  font-size: 24px;
  font-weight: 600;
}

.autumnItemOnlyImage {
  flex-shrink: 0;
}

.autumnItemImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 1400px) {
  .containerLanding {
    width: 1320px;
  }

  .locationContainer {
    padding-left: 60px;
  }

  .iconListItem {
    width: 350px;
  }

  .iconListItem2 {
    width: 350px;
  }

  .why::before {
    left: -350px;
  }

  .whyContent {
    padding-left: 475px;
  }

  .benefit {
    padding-left: 475px;
  }

}

@media (max-width: 768px) {
  .containerLanding {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    margin: 0 auto;
  }

  /* intro */
  .intro {
    margin-top: -75px;
  }

  .introTitle {
    font-size: 16px;
    line-height: 27px;
  }

  .introTitle .textColorGreen br {
    display: inline;
  }

  .introTitleBig {
    font-size: 24px;
    letter-spacing: 1.2px;
  }

  .introContainer {
    display: block;
    padding: 30px 26px;
  }

  .introImgSlogan {
    width: 36px;
  }

  .introContainerSlogan {
    margin-bottom: 16px;
  }

  .introImgSlogan {
    height: auto;
  }

  /* location */
  .locationQuoteText {
    font-size: 18px;
    margin-bottom: 16px;
  }

  .locationQuoteCite {
    font-size: 14px;
    letter-spacing: normal;
  }

  .locationInfoTitle {
    font-size: 24px;
    letter-spacing: 1.2px;
  }

  .locationInfoText {
    font-size: 16px;
  }

  .locationInfoMainVideoTitle {
    font-size: 24px;
    letter-spacing: 1.2px;
  }

  .locationInfoMainVideoText {
    font-size: 16px;
  }

  .locationInfoMainImageQuoteText {
    font-size: 16px;
  }

  .locationInfoMainImageQuoteCite {
    font-size: 14px;
  }

  .locationQuote {
    width: 350px;
    min-height: 140px;
    border-radius: 10px 10px 0 0;
    padding: 20px;
  }

  .locationContainer {
    padding: 29px 26px 47px 25px;
  }

  .locationInfoHeader {
    display: block;
  }

  .locationInfoMain {
    flex-wrap: wrap;
  }

  .locationInfoMainImage {
    max-width: none;
    order: -1;
    margin: 0;
  }

  .locationInfoMainImageQuote {
    cursor: default;
    position: static;
    background: none;
    order: -1;
  }

  .locationInfoMainImage {
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }

  .locationInfoMainImageQuoteText span {
    display: block;
  }

  .locationInfoMainImageQuoteText span:first-child {
    margin-bottom: 30px;
  }

  .locationInfoMainImageItem {
    flex-shrink: 0;
    max-width: 125px;
  }

  .locationInfoMainVideoItem iframe {
    width: 100%;
    height: 295px;
    border-radius: 12px;
  }

  .locationInfoMainVideo {
    max-width: none;
    padding-top: 50px;
  }

  .videoContainer {
    padding: 0;
  }

  /* iconList */
  .iconListTitle {
    font-size: 30px;
    letter-spacing: normal;
    padding: 0;
    margin-bottom: 10px;
    position: static;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }

  .iconListTextWhite .iconListTitle {
    margin-bottom: 10px;
  }

  .iconListTitle br {
    display: none;
  }

  .iconListText {
    font-size: 22px;
    font-weight: 600;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }

  .iconList {
    row-gap: 30px;
  }

  .iconListItem {
    position: relative;
    cursor: default;
    width: auto;
    padding-left: 70px;
  }

  .iconListTitle::before {
    left: 0;
    top: 0;
  }

  .iconListTitleIcon1::before {
    width: 61px;
    height: 64px;
    background-image: url("../../static/images/landings/kimchi/icon-1-mobile.png");
  }

  .iconListTitleIcon2::before {
    width: 54px;
    height: 54px;
    background-image: url("../../static/images/landings/kimchi/icon-2-mobile.png");
  }

  .iconListTitleIcon3::before {
    width: 54px;
    height: 54px;
    background-image: url("../../static/images/landings/kimchi/icon-3-mobile.png");
  }

  .iconListTitleIcon4::before {
    width: 54px;
    height: 54px;
    background-image: url("../../static/images/landings/kimchi/icon-4-mobile.png");
  }

  .iconListItem3 {
    display: flex;
    min-height: 190px;
  }

  .iconListItemVideoItem {
    flex-shrink: 0;
  }

  .iconListItemVideoItem iframe {
    margin-left: 15px;
    aspect-ratio: 16/9;
    border-radius: 12px;
  }

  /* why */
  .why {
    border-radius: 0;
    overflow: hidden;
    background-color: transparent;
  }

  .why::before {
    background-image: url("../../static/images/landings/kimchi/why-bg-m.svg"), linear-gradient(to right, #fff 50%,  var(--alt-color) 50%);
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: -250px 100%, center;
  }

  .whyContent {
    padding: 24px 20px 35px 30px;
  }

  .whyTitle {
    font-size: 24px;
    letter-spacing: 1.2px;
    margin-bottom: 36px;
  }

  /* benefit */
  .benefitTitle {
    font-size: 24px;
    letter-spacing: 1.2px;
    margin-bottom: 36px;
  }

  .benefit {
    padding: 35px 26px 32px 27px;
  }

  /* info */
  .infoTitle {
    font-size: 24px;
    letter-spacing: 1.2px;
    margin-bottom: 30px;
  }

  .infoTitle br {
    display: none;
  }

  .infoTex {
    font-size: 16px;
  }

  .infoHeaderContainer {
    padding: 0 26px 23px;
  }

  .infoHeader {
    flex-wrap: wrap;
  }

  .infoHeaderTextContent {
    order: -1;
    margin-left: 0;
    margin-bottom: 20px;
  }

  .infoImg {
    width: 100%;
  }

  .infoListContainer {
    padding: 0;
  }

  .infoList {
    padding: 24px 25px 125px 25px;
  }

  .infoItemText {
    font-size: 20px;
    border-radius: 12px;
    position: absolute;
    flex-shrink: 0;
    max-width: 386px;
    min-height: 229px;
    padding: 30px 15px 30px;
    transform: none;
    top: 140px;
    right: 5px;
  }

  .infoItem {
    position: relative;
    margin-bottom: 160px;
    padding-right: 140px;
  }

  .infoItem:last-child {
    margin-bottom: 0;
  }

  .infoItemText span {
    display: block;
  }

  .infoItemText span:first-child {
    margin-bottom: 25px;
  }

  .infoItem:nth-child(2n) .infoItemText {
    order: 0;
    padding: 30px 25px 15px 30px;
    transform: none;
    top: 130px;
  }

  .infoItem:nth-child(2n) .infoItemImg {
    margin: 0;
  }

  .infoItemImg {
    flex-shrink: 0;
    width: 100%;
    height: auto;
  }


  /* autumn */
  .autumnTitle {
    font-size: 24px;
    letter-spacing: 1.2px;
    margin-bottom: 12px;
  }

  .autumnTitle br {
    display: inline;
  }

  .autumnText {
    font-size: 16px;
  }

  .autumnHeader {
    display: block;
    margin-bottom: 20px;
  }

  .autumnItemText {
    font-size: 16px;
  }

  .autumnContainer {
    padding: 41px 26px;
  }

  .autumnList {
    flex-wrap: wrap;
    gap: 20px;
  }

  .autumnItem {
    flex-shrink: 0;
    max-width: 48%;
  }

  .autumnItemOnlyImage {
    max-width: none;
    width: 100%;
  }

  /* .autumnItemText span {
    display: block;
  }

  .autumnItem:nth-child(3) .autumnItemText span:first-child {
    margin-bottom: 10px;
  } */
}
