
.catalog-products {
    margin-top: 56px;
    display: grid;
    grid-template-columns: 311px 311px 311px;
    grid-gap: 25px;
}

.catalog-products .product-card {
    margin-left: 0;
    z-index: 1;
}

.catalog-holder {
    display: flex;
}

.catalog-col:nth-child(1) {
    width: 312px;
    min-width: 312px;
    margin-right: 25px;
}

.catalog-col:nth-child(2) {
    width: 100%;
}

.catalog-col--clearfix {
    height: 56px;
}

.catalog-menu > div {
    margin-bottom: 20px;
}

.catalog-menu__categories {
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.catalog-menu__categories--title {
    font-family: "Raleway Black", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    color: #363538;
    border-bottom: 1px solid rgb(54 53 56 / 0.5);
    padding-bottom: 6px;
    line-height: 35px;
    box-sizing: border-box;
}

.catalog-menu__categories--items {
    display: flex;
    flex-direction: column;
    min-height: 200px;
}

.catalog-menu__categories--items .loader {
    margin-top: 60px;
    z-index: 10;
}

.catalog-menu__categories--item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.catalog-menu__categories--item a {
    font-size: 20px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    text-decoration: none;
    margin: 12px 0;
}

.catalog-filters {
    display: flex;
    justify-content: space-between;
}


.catalog-filters__labels {
    display: flex;
    z-index: 2;
}

.catalog-filters__filter {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 14px 15px 14px 10px;
    background: #FFFFFF;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.catalog-filters__labels.selected .catalog-filters__filter {
    background: #c3d7a6;
}

.catalog-filters__filter.active {
    background: #fff !important;
}

.catalog-filters__filter + .catalog-filters__filter {
    margin-left: 18px;
}

.catalog-filters__filter-title .text {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    white-space: nowrap;

}

.catalog-filters__filter-icon {
    display: flex;
    margin-left: 6px;
    width: 30px;
    justify-content: center;
}

.catalog-row {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.catalog-text {
    padding: 0 150px;
    width: 100%;
}