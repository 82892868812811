.parallax, .static-images {
    position: absolute;
    top: 0;
    left: 0;
}

.parallax img, .static-image {
    position: absolute;
    z-index: 3;
}

.parallax-aware {
    position: relative;
}

img.parallax-image.banner_cherry {
    top: 651px;
    left: -113px;
}
.banner_zelen {
    width: 70px;
    height: 70px;
}
img.parallax-image.banner_zelen {
    top: 382px;
    left: 573px;
}

img.parallax-image.banner_spinach2 {
    top: 461px;
    left: 1120px;
}
.banner_spinach1 {
    width: 67px;
    height: 70px;
}
img.parallax-image.banner_spinach1 {

    top: 64px;
    left: 162px;
}
.banner_pepper2 {
    width: 100px;
    height: 90px;
}
img.parallax-image.banner_pepper2 {
    top: 456px;
    left: 534px;
}
.banner_pepper1 {
    width: 54px;
    height: 42px;
}
img.parallax-image.banner_pepper1 {

    top: 425px;
    left: 493px;
}
.banner_spinach3 {
    width: 81px;
    height: 64px;
}
img.parallax-image.banner_spinach3 {
    left: 19px;
    top: 725px;
}
.banner_tomat_2 {
    width: 100px;
    height: 100px;
}
img.parallax-image.banner_tomat_2 {
    left: 77px;
    top: 684px;
}
.banner_tomat_1 {
    width: 102px;
    height: 102px;
}
img.parallax-image.banner_tomat_1 {
    left: 9px;
    top: 651px;
}

img.parallax-image.banner_g3 {
    left: 0;
    top: 729px;
}

img.parallax-image.banner_g2 {
    left: 16px;
    top: 723px;
}

img.parallax-image.banner_g1 {
    left: 25px;
    top: 730px;
}
.hits_pepper {
    width: 91px;
    height: 100px;
}
img.parallax-image.hits_pepper {

    left: 931px;
    top: 750px;
}
.hits_zelen {
    width: 100px;
    height: 76px;
}
img.parallax-image.hits_zelen {
    left: 872px;
    top: 736px;
}
.five_rules_spinach3 {
    width: 182px;
    height: 234px;
}
img.parallax-image.five_rules_spinach3 {
    left: 1085px;
    top: 122px;
}
.five_rules_pepper {
    width: 130px;
    height: 110px;
}
img.parallax-image.five_rules_pepper {
    left: 909px;
    top: 45px;
}

img.parallax-image.five_rules_spinach2 {
    top: -75px;
    left: 34px;
}
.five_rules_broccoli {
    width: 127px;
    height: 117px;
}
img.parallax-image.five_rules_broccoli {
    top: 812px;
    left: 1051px;
}
.five_rules_carrot {
    width: 110px;
    height: 110px;
}

img.parallax-image.five_rules_carrot {
    top: 397px;
    left: 995px;
}

img.parallax-image.five_rules_spinach1 {
    top: -121px;
    left: 134px;
}

img.parallax-image.five_rules_g1 {
    top: 884px;
    left: 470px;
}

img.parallax-image.five_rules_g2 {
    top: 851px;
    left: 451px;
}

img.parallax-image.five_rules_g3 {
    top: 835px;
    left: 487px;
}

img.parallax-image.five_rules_g4 {
    top: 889px;
    left: 424px;
}
.testimonials_pepper {
    width: 223px;
    height: 248px;
}

img.parallax-image.testimonials_pepper {
    top: -94px;
    left: 369px;
}
.about_spinach {
    width: 420px;
    height: 158px;
}

img.parallax-image.about_spinach {
    top: -158px;
    left: 157px;
}
.about_tomato {
    width: 100px;
    height: 100px;
}

img.parallax-image.about_tomato {
    top: 564px;
    left: 134px;
}

img.parallax-image.about_g1 {
    top: 546px;
    left: 104px;
}

img.parallax-image.about_g2 {
    top: 566px;
    left: 112px;
}

img.parallax-image.about_g3 {
    top: 560px;
    left: 125px;
}

.faq_spinach1 {
    width: 140px;
    height: 140px;
}

img.parallax-image.faq_spinach1 {
    left: 499px;
    top: -52px;
}

.faq_spinach2 {
    width: 92px;
    height: 92px;
}

img.parallax-image.faq_spinach2 {
    top: 276px;
    left: 614px;
}

.faq_pepper {
    width: 114px;
    height: 116px;
}

img.parallax-image.faq_pepper {
    top: 634px;
    left: 1177px;
}

img.static-image.hits_text {
    left: 1098px;
    top: -285px;
}

img.static-image.five_rules_text {
    left: 1129px;
    top: 437px;
}

img.static-image.restaurants_text {
    left: 1134px;
    top: -23px;
}

img.static-image.about_elk {
    left: 336px;
    top: 177px;
}

img.static-image.about_ieg {
    top: 223px;
    left: 10px;
}

img.static-image.about_team_arrow {
    top: 540px;
    left: 260px;
    opacity: 0;
}

img.static-image.about_chief_arrow {
    left: 716px;
    top: -14px;
    opacity: 0;
}

span.static-image.about_chief_text.text--handwritten {
    left: 604px;
    top: -45px;
    color: #fff;
    font-size: 54px;
    opacity: 0;
}

span.static-image.about_team_text.text--handwritten {
    font-size: 54px;
    top: 450px;
    left: 195px;
    opacity: 0;
}

.active img.static-image.about_chief_arrow,
.active span.static-image.about_chief_text.text--handwritten {
    opacity: 1;
    transition: all .5s;
    animation: reveal-from-right-animation 0.7s ease;
}

.active img.static-image.about_team_arrow,
.active span.static-image.about_team_text.text--handwritten {
    opacity: 1;
    transition: all .5s;
    animation: reveal-from-left-animation 0.7s ease;
}

img.static-images.banner_text {
    right: 0;
    left: initial;
    top: 165px;
    z-index: 1;
}

.main-banner__image img {
    z-index: 2;
    position: relative;
}

@media (max-width: 768px) {
    .main-banner__image img {
        max-width: 100%;
    }

    img.static-image.banner_pepper1 {
        top: 310px;
        left: 48px;
    }

    img.static-image.banner_pepper2 {
        top: 838px;
        left: 415px;
    }

    img.static-image.banner_spinach1 {
        top: -117px;
        left: 120px;
    }

    img.static-image.banner_spinach2 {
        top: 251px;
        left: 420px;
    }

    img.static-image.banner_zelen {
        top: 120px;
        left: 340px;
    }

    img.static-image.banner_g1 {
        top: 1485px;
        left: 370px;
    }

    img.static-image.banner_g2 {
        top: 1475px;
        left: 359px;
    }

    img.static-image.banner_g3 {
        top: 1483px;
        left: 339px;
    }

    img.static-image.banner_tomat_1 {
        top: 1396px;
        left: 361px;
    }

    img.static-image.banner_tomat_2 {
        top: 1435px;
        left: 442px;
    }

    img.static-image.banner_spinach3 {
        top: 1485px;
        left: 370px;
    }

    img.static-image.hits_zelen {
        top: 862px;
        left: 100px;
    }

    img.static-image.hits_pepper {
        top: 880px;
        left: 160px;
    }

    img.static-image.five_rules_spinach1 {
        top: -140px;
        left: 147px;
    }

    img.static-image.five_rules_spinach2 {
        top: -94px;
        left: 50px;
    }

    img.static-image.five_rules_spinach3 {
        top: 1485px;
        left: 282px;
    }

    img.static-image.five_rules_g2 {
        top: 1483px;
        left: 545px;
    }

    img.static-image.five_rules_pepper {
        top: 324px;
        left: 105px;
    }

    img.static-image.testimonials_pepper {
        left: 353px;
        top: -105px;
    }

    img.static-image.about_spinach {
        top: -157px;
        left: 77px;
    }

    img.static-image.about_tomato {
        top: 1440px;
        left: 450px;
    }

    img.static-image.about_g1 {
        top: 1423px;
        left: 420px;
    }

    img.static-image.about_g2 {
        top: 1444px;
        left: 428px;
    }

    img.static-image.about_g3 {
        top: 1438px;
        left: 441px;
    }

    img.static-image.faq_spinach1 {
        top: 720px;
    }

    img.static-image.faq_spinach2 {
        top: 1145px;
        left: 450px;
    }

    img.static-image.faq_pepper {
        top: 1530px;
        left: 427px;
    }

    img.static-image.hits_text {
        left: 284px;
        top: -114px;
        z-index: 1;
    }

    img.static-image.five_rules_pibimpab {
        left: 260px;
        top: 468px;
    }

    img.static-image.five_rules_text {
        left: 248px;
        top: 315px;
        z-index: 19;
    }

    img.static-image.five_rules_sticks {
        top: 1040px;
        left: 251px;
    }

    img.static-image.about_ieg {
        top: 321px;
        left: 95px;
    }

    img.static-image.about_elk {
        top: 257px;
        left: 433px;
    }

    img.static-image.about_chief_arrow {
        top: 1011px;
        left: 333px;
        opacity: 1;
    }

    img.static-image.about_team_arrow {
        top: 1563px;
        left: 51px;
        opacity: 1;
    }

    span.static-image.about_chief_text.text--handwritten {
        color: #363538;
        top: 1101px;
        font-size: 54px;
        left: 248px;
        opacity: 1;
    }

    span.static-image.about_team_text.text--handwritten {
        top: 1525px;
        font-size: 54px;
        left: 221px;
        opacity: 1;
    }

    img.static-image.restaurants_text {
        left: 370px;
        top: 55px;
        z-index: 1;
    }

    img.zone-modal__inner-image {
        display: none;
    }
}

img.zone-modal__inner-image.zone-modal__inner-image--g1 {
    left: -20px;
    top: 60px;
}

img.zone-modal__inner-image {
    position: absolute;
}

.zone-modal__inner {
    position: relative;
}

img.zone-modal__inner-image.zone-modal__inner-image--g2 {
    left: -50px;
    top: 83px;
}

img.zone-modal__inner-image.zone-modal__inner-image--list {
    left: -80px;
    top: -10px;
}

img.zone-modal__inner-image.zone-modal__inner-image--ginger {
    bottom: -90px;
    left: 870px;
}