
.intro {
    display: flex;
    justify-content: space-between;
}

.introText {
    position: relative;
}

.introImage {
    z-index: 20;
    top: -100px;
    position: relative;
}

.title {
    max-width: 650px;

    font-family: 'Raleway Black', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 55px;
    line-height: 65px;
    text-transform: uppercase;
    margin: 0;
}

.subtitle {
    font-family: 'HitchHike', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 45px;
    text-transform: lowercase;
    max-width: 450px;

    margin-left: 210px;
    margin-top: 75px;
}

.titleArrow {
    position: absolute;
    right: -50px;
    top: 150px;
}

.introAfterText {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    max-width: 925px;
}

.elements {
    margin-top: 120px;
    padding: 0 58px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 48px;
}

.elementsTitle {
    font-family: 'Raleway Black', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 47px;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin: 0;
}

.elementsGrid {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto 1fr;
}

.elementsCard {
    display: flex;
    flex-direction: column;
    padding: 48px 32px;
    gap: 20px;

    background: rgba(136, 176, 75, 0.2);
    border-radius: 20px;
    box-sizing: border-box;
}

.elementsHead {
    font-family: 'Raleway Black', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 23px;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #88B04B;
}

.elementsText {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
}

.elementsTile:nth-child(1),
.elementsTile:nth-child(2),
.elementsTile:nth-child(3) {
    grid-column: span 2;
}

.elementsTile:nth-child(4),
.elementsTile:nth-child(5) {
    grid-column: span 3;
}

.steps {
    padding: 0 58px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 200px;
}

.stepsInner {
    display: flex;
    gap: 24px;
    position: relative;
    align-items: center;

}

.stepsInner > img {
    position: absolute;
    bottom: -70px;
    left: 300px;
}

.stepsImage {
    margin-left: -20px;
}

.stepsTitle {
    font-family: 'Raleway Black', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    position: relative;
    margin: 0;
}

.stepsTitle img {
    position: absolute;
    right: 0;
    top: -100px;
}

.stepsGrid {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto;
    height: 100%;
}

.stepsCard {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #F8F7F7;
    background: #88B04B;
    border-radius: 20px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px 32px;
    gap: 10px;
}


.stepsCard:nth-child(1) {
    grid-column: span 4;
}

.stepsCard:nth-child(2),
.stepsCard:nth-child(3) {
    grid-column: span 2;
}


.how {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    margin-top: 200px;
}

.howTitle {
    font-family: 'Raleway Black', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 47px;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin: 0;
}

.howInner {
    background: #88B04B;
    display: flex;
    width: 100%;
}

.howBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 72px;
    gap: 36px;
}

.howHead {
    font-family: 'Raleway Black', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 41px;
    /* or 128% */
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #F8F7F7;
}

.howText {
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #F8F7F7;
}

.disclamer {
    padding: 0 170px;
    margin-top: 80px;
    display: flex;
    gap: 72px;
    align-items: center;
    position: relative;
}

.disclamer > img {
    position: absolute;
    bottom: -25px;
    right: 170px;
}

.disclamerBody {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.disclamerHead {
    font-family: 'Raleway Black', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
}

.disclamerText {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
}

.form {
    margin-top: 170px;
    padding: 0 172px;
    display: grid;
    grid-template-columns: auto 340px;
    margin-bottom: 220px;
}

.formInner {
    background: #E1E9D5;
    padding: 100px 72px;
    border-radius: 20px 0 0 20px;
}

.formTitle {
    font-family: 'Raleway Black', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 55px;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    position: relative;
    margin: 0;
}


.formText {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    /* or 135% */
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    margin-top: 32px;
}

.formImage {
    background-image: url("../../static/images/landings/service/form.png");
    background-size: cover;
    background-position: center;
    border-radius: 0 20px 20px 0;
}

.formSelector {
    margin-top: 32px;
    cursor: pointer;
}


.stars {
    margin-top: 55px;
    display: flex;
    gap: 45px;
}

.star {
    cursor: pointer;
}

.questionsTitle {
    font-style: italic;
    font-weight: 700;
    font-size: 17px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
}

.formQuestions {
    margin-top: 52px;
}

.questionsItems {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.question {
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.formInputs {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.formInputsRow {
    display: flex;
    gap: 24px;
}

.formInputs input {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 20px;
    gap: 15px;
    font-size: 18px;
    background: #FFFFFF;
    border-radius: 5px;

    border: none;
}

.formInputs input, .formInputs input::placeholder {
    font-family: 'Raleway', sans-serif;
}


.formInputs input::placeholder {
    opacity: 0.5;
    color: #363538;
    font-size: 18px;
    font-family: 'Raleway', sans-serif;
}

.button {
    margin-top: 32px;

    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;

    color: #FFFFFF;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 39px;

    background: #92B759;
    border-radius: 5px;
    cursor: pointer;
}


@media (max-width: 768px) {
    .intro {
        flex-direction: column;
    }

    .subtitle {
        margin-top: 25px;
        margin-left: 170px;
    }

    .titleArrow {
        transform: rotate(95deg) scale(1, -1);
        transform-origin: center;
        top: 360px;
        right: initial;
    }

    .introImage {
        top: 60px;
        width: 100%;
        padding: 0 40px;
        box-sizing: border-box;
    }

    .introImage img {
        width: 100%;
    }

    .introAfterText {
        margin-top: 120px;

        font-size: 23px;
        line-height: 30px;
    }

    .elements {
        padding: 0 20px;
    }

    .elementsGrid {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .elementsTile img {
        width: 100%;
    }

    .elementsTile:nth-child(1) {
        order: 1;
    }

    .elementsTile:nth-child(2) {
        order: 3;
    }

    .elementsTile:nth-child(3) {
        order: 5;
    }

    .elementsTile:nth-child(4) {
        order: 2;
    }

    .elementsTile:nth-child(5) {
        order: 4;
    }

    .elementsHead {
        font-size: 32px;
        line-height: 34px;
    }

    .elementsText {
        font-size: 20px;
        line-height: 23px;
        color: #363538;
        font-weight: 500;
    }

    .steps {
        margin-top: 180px;
        padding: 0 20px;
        position: relative;
    }

    .stepsTitle {
        text-align: left;
    }

    .stepsTitle > img {
        display: none;
    }

    .stepsInner {
        flex-direction: column;
    }

    .stepsImage {
        position: absolute;
        right: -20px;
    }

    .stepsInner > img {
        position: absolute;
        left: -20px;
        top: 10px;
    }

    .stepsGrid {
        margin-top: 620px;
        flex-direction: column;
        display: flex;
    }

    .how {
        margin-top: 160px;
        gap: 40px;
    }

    .howTitle {
        padding: 0 20px;
        margin: 0;
    }

    .howInner {
        display: flex;
        flex-direction: column;
    }

    .howImage img {
        width: 100%;
    }

    .howBody {
        padding: 72px 24px;
    }

    .howText {
        font-size: 24px;
        line-height: 34px;
    }

    .disclamer {
        margin-top: 120px;
        flex-direction: column-reverse;
        padding: 0 20px;
    }

    .disclamer > img {
        bottom: 315px;
        right: 40px;
    }

    .disclamerText {
        font-size: 24px;
        line-height: 32px;
    }

    .form {
        padding: 0 20px;
        flex-direction: column;
        display: flex;
        margin-bottom: 180px;
    }

    .formInner {
        border-radius: 20px 20px 0 0;
        padding: 95px 50px 32px 50px;
    }

    .formTitle {
        font-size: 55px;
        line-height: 65px;
    }

    .formImage {
        min-height: 350px;
        border-radius: 0 0 20px 20px ;
    }

    .question {
        font-size: 17px;
        line-height: 23px;
    }

    .formInputsRow {
        flex-direction: column;
        gap: 16px;
    }

    .formInputs input {
        box-sizing: border-box;
    }

    .button {
        margin-top: initial;
    }
}