.order-success__info-greeting > .text {
    margin: 10px 0;
    max-width: 350px;
}

.order-success__info {
    margin-top: 32px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
}

.order-success__info-delivery {
    margin-top: 40px;
}

.order-success__info-delivery--title {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
}

.order-success__info-delivery--description {
    margin-top: 20px;
}

.order-success__info-delivery--description {
    display: grid;
    grid-template-columns: 20% 30% 20%;
    grid-gap: 20px;
}

.order-success__info-delivery--description .col > * {
    height: 20px;
    width: 100%;
}

.order-success__info-delivery--description > .col > * + *  {
    margin-top: 14px;
}

.order-success__info-delivery--description .col {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

.order-success__info-delivery--description a {
    text-decoration: underline;
}

.order-success__info-actions {
    display: flex;
    margin-top: 85px;
}

.order-success__info-actions .button + .button {
    margin: 0 0 0 25px;
}

.order-success__info-actions > .button-green {
    width: 140px;
}

.order-success__info-actions > .button-black {
    width: 290px;
}

img.order-success-static--onion {
    position: absolute;
    left: 1021px;
    top: -100px;
}

.order-success.container.container-pd {
    position: relative;
}

img.order-success-static--petr2 {
    position: absolute;
    left: 390px;
    top: -100px;
}

img.order-success-static--pickle {
    position: absolute;
    left: 1250px;
    top: 70px;
}

img.order-success-static--petr1 {
    position: absolute;
    left: 1170px;
    top: -110px;
}