input::placeholder, textarea::placeholder {
    font-family: 'Raleway', sans-serif;
}

.input {
    border: none;
    padding: 18px 20px;
    background: #FFFFFF;
    border-radius: 5px;
    color: #363538;
}

.input + .input {
    margin-top: 10px;
}

.input::placeholder{
    opacity: 0.5;
    color: #363538;
    font-size: 18px;
    font-family: 'Raleway', sans-serif;
}

.search-input {
    display: flex;
    align-items: center;
    padding: 13px 10px;
    background: #FFFFFF;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    box-sizing: border-box;
    line-height: 10px;
}

.search-input--input .input {
    padding: 0;
}

.search-input--icon {
    width: 45px;
}

.search-input .input {
    font-size: 17px;
}

.search-input--input .input {
    padding: 0;
}

.search-input--icon {
    width: 45px;
}
