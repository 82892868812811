.intro {

}

.map {
    margin-top: 70px;
    display: flex;
    gap: 75px;
}

.mapComponent {
    max-width: 600px;
    width: 100%;
}

.title {
    font-family: 'Raleway Black', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 55px;
    line-height: 65px;
    text-transform: uppercase;
}

.titleSmall {
    font-family: 'Raleway Black', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 46px;
    text-transform: uppercase;
}

.text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    /* or 135% */
    font-feature-settings: 'pnum' on, 'lnum' on;
}

.introText {
    width: 725px;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    /* or 135% */
    font-feature-settings: 'pnum' on, 'lnum' on;

    /* text */
    color: #363538;
}


.introButton {
    cursor: pointer;
    width: 450px;
    margin-top: 42px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px;
    text-decoration: none;
    gap: 18px;
    /* Main color */
    background: #92B759;
    border-radius: 5px;
}

.introButton span:nth-child(1) {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;

    /* White */
    color: #FFFFFF;
}

.introButton span:nth-child(2) {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;

    color: #FFFFFF;
}

.about {
    margin-top: 160px;
}

.aboutTexts {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: space-between;
}

.aboutText {
    width: 100%;
}

.menu {
    margin-top: 80px;
    display: flex;
    gap: 48px;
    justify-content: flex-start;
    padding-right: 78px;
}

.menuImage {
    margin-top: 50px;
}


.menuPreTitle {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 47px;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    padding: 40px 80px 40px 175px;

    background: #88B04B;
    border-radius: 0 10px 10px 0;
}

.menuText {
    margin-top: 40px;
    padding: 0 0 0 175px;
}

.greenSections {
    width: 1320px;
    margin: 78px auto 0;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.greenSectionReverse,
.greenSection {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 45px;
    background: rgba(136, 176, 75, 0.2);
    border-radius: 20px;
}

.greenSectionReverse img,
.greenSection img {
    height: 100%;
    object-fit: cover;
    width: 583px
}

.greenSection {
    grid-template-columns: 1fr auto;
}

.greenSectionReverse {
    grid-template-columns: auto 1fr;

}

.greenSectionReverse .greenSectionText {
    padding: 45px 15px 50px 0;

}

.greenSection .greenSectionText {
    padding: 45px 0 50px 55px;
}

.greenSectionText {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.greenSection img {
    border-radius: 0px 20px 20px 0;
}

.greenSectionReverse img {
    border-radius: 20px 0px 0px 20px;
}

.hits {
    margin-top: 54px;
    background: #E1E9D5;
    padding: 130px 40px 100px;
}

.hits .title {
    max-width: 1120px;
    margin: 0 auto;
}

.hitsImages {
    margin-top: 66px;
    display: grid;
    grid-template-columns: auto auto;
    gap: 24px;
    justify-content: center;
}

.hitsMenus {
    margin: 0 auto;
    margin-top: 100px;
    max-width: 1077px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hitMenu {
    width: 100%;
}

.hitMenu + .hitMenu {
    margin-top: 45px;
    padding-top: 23px;
    border-top: 1px solid rgba(54, 53, 56, 0.48)
}

.hitMenuTitle {
    cursor: pointer;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 900;
    font-size: 26px;
    line-height: 31px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 40px

}

.hitMenuTitle img {
    transition: all .3s;
    transform: rotate(90deg);
}

.hitMenuHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hitMenuButton {
    text-decoration: none;
    width: 275px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 39px;
    background: #92B759;
    border-radius: 5px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
}

.collapse {
    transition: all .3s;
}

.hitMenuText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    padding-top: 40px;
}

.hitMenuText a {
    color: #363538;
}

.disclamer {
    width: 1100px;
    margin: 0 auto;
    padding-bottom: 200px;
}

.disclamerSection {
    margin-top: 60px;
    display: flex;
    gap: 70px;
}

.disclamerImages {
    position: relative;
    width: 100%;
    min-width: 500px;
    height: 600px;
    margin: 60px auto auto;
    max-width: 1100px;
}

.disclamerImages img {
    position: absolute;
}

.disclamerImages img:nth-child(1) {
    z-index: 2;
}

.disclamerImages img:nth-child(2) {
    top: 55px;
    left: 55px;
    z-index: 1;
}

.disclamerButtons {
    margin-top: 65px;
    display: flex;
    gap: 16px;
}

.disclamerButtonGreen {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 39px;
    text-decoration: none;
    background: #92B759;
    border-radius: 5px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
}

.disclamerButtonWhite {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    padding: 13px 39px;
    background: #E1E9D5;
    border-radius: 5px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #383735;

}

.aboutShow {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-decoration-line: underline;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    width: 100%;
    cursor: pointer;
}


@media (max-width: 768px) {

    .introButton {
        width: 100%;
        box-sizing: border-box;
    }

    .text {
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 30px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #363538;
    }

    .introText {
        width: 100%;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 30px;
        /* or 130% */
        font-feature-settings: 'pnum' on, 'lnum' on;

        /* text */
        color: #363538;
    }

    .aboutTexts {
        display: flex;
        flex-direction: column;
    }

    .menu {
        display: flex;
        flex-direction: column;
    }

    .greenSection .greenSectionText,
    .greenSectionReverse .greenSectionText {
        padding: 0 23px;
    }

    .greenSection {
        padding: 72px 0 0 0;
        display: flex;
        flex-direction: column;
        border-radius: 0;
    }

    .greenSection img, .greenSectionReverse img {
        width: 100%;
        object-fit: cover;
        border-radius: 0;
    }

    .greenSectionReverse {
        padding: 72px 0 0 0;
        border-radius: 0;
        display: flex;
        flex-direction: column-reverse;
    }

    .hitsImages {
        display: flex;
        flex-direction: column;
    }

    .hitsMenus {
        width: 100%;
    }

    .greenSections {
        gap: 0;
        width: 100%;
    }

    .disclamer {
        width: 100%;
        padding: 0 32px;
        box-sizing: border-box;
    }

    .disclamerSection {
        display: flex;
        flex-direction: column;
    }

    .disclamerImages {
        width: 100%;
        height: 500px;
    }

    .disclamerImages img {
        width: 95%;
    }

    .menuImage {
        width: 100%;
        margin-bottom: 110px;
    }

    .menuImage img {
        width: 100%;
        object-fit: cover;
    }

    .map {
        flex-direction: column-reverse;
    }

    .mapComponent {
        width: calc(100% + 40px);
        margin-left: -20px;
    }


    .hitMenu {
    }

    .hitMenu + .hitMenu {
        padding-top: 45px;
    }

    .hitMenuButton {
        margin: 52px auto 0;
    }

    .aboutTexts {
        gap: 0;
    }

    .hits {
        background: unset;
        padding: 76px 0 100px;
    }

    .hitsMenus {
        padding: 55px 0;
        background: #E1E9D5;
    }

    .hitMenu {
        padding: 0 20px;
        box-sizing: border-box;
    }

    .hitsImages {
        padding: 0 32px;
    }

    .hits .title {
        padding: 0 32px;
        font-weight: 900;
        font-size: 40px;
        line-height: 130%;
    }

    .mobImages {
        margin-top: 50px;
        position: relative;
        height: 600px;
    }

    .mobImages img {
        position: absolute;
        max-width: 100%;
    }

    .mobImages img:nth-child(1) {
        top: 100px;
        left: 50px;
        z-index: 3
    }

    .mobImages img:nth-child(2) {
        top: 400px;
        left: 75px;
        z-index: 2
    }

    .mobImages img:nth-child(3) {
        height: 100%;
        object-fit: cover;
        width: 100%;
        z-index: 1
    }

    .hitsMenus {
        position: relative;
    }

    .hitsMenus > img {
        position: absolute;
        top: -50px;
        right: 50px;
    }

    .menu {
        padding: 0;
        margin-bottom: 24px;
    }

    .menuPreTitle {
        font-family: "Raleway Black";
        box-sizing: border-box;
        padding: 32px 25px;
        width: 100%;
        font-style: normal;
        font-weight: 900;
        font-size: 32px;
        line-height: 40px;
        /* or 131% */
        text-transform: uppercase;
        font-feature-settings: 'pnum' on, 'lnum' on;
        border-radius: 0;

        color: #FFFFFF;
    }

    .aboutShow {
        text-align: right;
        width: 100%;
    }

    .menuText {
        padding: 0 25px;
        width: 100%;
        box-sizing: border-box;
    }

    .menuText .title {
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 130.4%;
        /* or 52px */
        text-transform: uppercase;

        color: #88B04B;
    }

    .disclamer {
        margin-top: 120px;
    }

    .disclamer h2 {
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 130.4%;
        text-transform: uppercase;
        color: #363538;
    }
}