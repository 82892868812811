.nav {
    padding: 40px 0;
    display: flex;
    width: 1320px;
    margin: 0 auto;
    position: relative;
}

.nav-logo {
    position: absolute;
    margin-top: 25px;
    cursor: pointer;
}

.nav-inner {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.nav-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 700px;
    margin-right: 30px;
}

.nav-menu__item {
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
}

.nav-menu__item:hover {
    /*font-weight: bold;*/
}

.nav-buttons {
    display: flex;
    align-items: center;
    margin-left: 30px;
}

.nav-phone {
    font-family: "Raleway Black", sans-serif;
    color: #fff;
    padding: 5px;
    font-size: 20px;
}

.nav-buttons__item {
    cursor: pointer;
}

.nav-buttons__item + .nav-buttons__item {
    margin-left: 22px;
}

.search-modal {
    position: absolute;
    top: 40px;
    right: 0;
    display: none;
    z-index: 1;
}

.search-modal--open {
    display: block;
}

.search-modal input {
    padding: 21px;
    width: 410px;
    border-radius: 10px;
    text-align: left;
    box-sizing: border-box;
}

.nav-buttons__item.search-icon {
    position: relative;
}

.search-modal__button {
    display: none;
}

.nav-close {
    position: absolute;
    right: 35px;
    top: 35px;
}

.page-restaurants .nav-menu__item,
.page-restaurants .nav-phone a {
    color: #363538;
}

.page-restaurants .nav-buttons__item > img {
    filter: invert(1);
}

.page-restaurants .cart-counter--count {
    color: #363538;
}

.page-restaurants .cart-counter--icon {
    border: 1.22727px solid #363538;
}

.page-vacancies img.static-images.banner_text,
.page-restaurants img.static-images.banner_text {
    display: none;
}

.nav-phone a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
}

.nav-phone a span {
    margin-right: 10px;
    height: 24px;
}

.nav-phone a span img {
    height: 24px;
}
