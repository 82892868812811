@keyframes appear {
    0% {
        opacity: 1%;
    }
    100% {
        opacity: 100%;
    }
}

@-webkit-keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes reveal-animation--one {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes reveal-animation--two {
    0% {
        opacity: 0;
    }
    33% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes reveal-animation--three {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes reveal-animation--four {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes reveal-animation--five {
    0% {
        opacity: 0;
    }
    45% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes reveal-circle-animation {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5) rotate(240deg);
        transform: scale(0.5) rotate(240deg);
    }
    5% {
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1) rotate(0deg);
        transform: scale(1) rotate(0deg);
    }
}

@keyframes reveal-circle-animation {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5) rotate(240deg);
        transform: scale(0.5) rotate(240deg);
    }
    5% {
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1) rotate(0deg);
        transform: scale(1) rotate(0deg);
    }
}

@-webkit-keyframes reveal-from-left-animation {
    0% {
        opacity: 0;
        -webkit-transform: translateX(200px);
        transform: translateX(200px);
    }
    30% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@keyframes reveal-from-left-animation {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-200px);
        transform: translateX(-200px);
    }
    30% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@-webkit-keyframes reveal-from-right-animation {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-200px);
        transform: translateX(200px);
    }
    30% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@keyframes reveal-from-right-animation {
    0% {
        opacity: 0;
        -webkit-transform: translateX(200px);
        transform: translateX(200px);
    }
    30% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

