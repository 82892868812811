.feedback-form {
    background: #88B04B33;
    border-radius: 5px;
}

.feedback-form {
    padding: 53px 83px;
}

.feedback-form__text {
    margin-top: 20px;
    width: 80%;
}

.feedback-form__form {
    display: flex;
    flex-direction: column;
    width: 88%;
    margin-top: 19px;
}

.feedback-form__form textarea {
    height: 88px;
    resize: none;
}


.feedback-form__button {
    margin-top: 30px;
    width: 350px;
}