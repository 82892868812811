.accordeon {
    display: flex;
    flex-direction: column;
}

.accordeon-item + .accordeon-item {
    margin-top: 25px;
}

.accordeon-item__description {
    overflow: hidden;
    max-height: 0;
    margin-left: 35px;
    width: 90%;
    line-height: 23px;
    transition: all .3s ease;
}

.accordeon-item__title {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.accordeon-item__title-icon {
    margin-right: 13px;
    transform: rotate(-90deg);
    transform-origin: center;
    transition: all .3s ease;
    cursor: pointer;
}

.accordeon-item.accordeon-item--opened .accordeon-item__title-icon {
    transform: rotate(0deg);
}

.accordeon-item.accordeon-item--opened .accordeon-item__description {
    max-height: 100px;
    margin-top: 27px;
    margin-bottom: 5px;
}

.accordeon-item__title-text {
    font-size: 20px;
    line-height: 25px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    font-weight: bold;
}
