.container.container-pd.confidential {
    padding-right: 240px;
}

.container.container-pd.confidential a {
    color:  #333;
}

.payment-delivery-map {
    min-width: 447px;
    min-height: 611px;
    height: 611px;
}

.payment-delivery-map iframe {
    border: none;
    height: calc(100% + 150px);
    width: 100%;
    margin-top: -55px;
    pointer-events: none;
}

.payment-delivery-map--holder {
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
}


@media (max-width: 768px) {
    .container.container-pd.confidential {
        padding-right: 20px;
    }

    .container.container-pd.confidential .title {
        font-size: 36px;
        line-height: 42px;
    }

    .payment-delivery-map {
        order: 0;
        width: calc(100% + 40px);
        padding: 0;
        height: 1px;
        margin-left: -20px;
        min-height: 635px;
        border-radius: unset;
        position: relative;
    }
}