.footer {
    background: #92B759;
    height: 250px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-col:nth-child(1) {
    width: 60%;
    display: flex;
    justify-content: flex-start;
}

.footer-col:nth-child(2) {
    width: 40%;
    display: flex;
    justify-content: space-between;
}

.footer-logo {
    display: flex;
    align-items: center;
}

.footer-menu {
    display: flex;
    align-items: center;
    margin: 0 95px 0 75px;
    justify-content: space-between;
    width: 100%;
}

.footer-menu__col {
    display: flex;
    flex-direction: column;
}

.footer-menu__col-item + .footer-menu__col-item {
    margin-top: 15px;
}

.footer-menu__col-item {
    font-size: 20px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none;
}

.footer-icons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.footer-menu {
    margin: 0 95px 0 75px;
    justify-content: space-between;
    width: 100%;
}

.footer-icons__row {
    display: flex;
    align-items: center;
}

.footer-icons__row:nth-child(1) img + img {
    margin-left: 10px;
}

.footer-icons__row:nth-child(2) img + img {
    margin-left: 25px;
}


.footer-copyright {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 105px;
    white-space: nowrap;
}

.footer-copyright .copyright {
    font-size: 20px;
    line-height: 25px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
}

.footer-icons__row:nth-child(2) img {
    height: 36px;
}

.footer-icons__row:nth-child(2) img:last-child {
    height: 32px;
}

