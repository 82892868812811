
.about .container {
    height: 463px;
    padding: 78px 0;
    box-sizing: border-box;
}

.about-holder {
    display: flex;
}

.about-title {
    min-width: 350px;
    width: 350px;
}

.about-summary {
    font-style: italic;
    font-size: 20px;
    line-height: 25px;
    color: #F8F7F7;
    margin-top: 21px;
}

.about-images {
    width: 100%;
    display: flex;
    margin-left: 85px;
}

.about-images__image {
    margin: 0 13px;
}


.about-images__image--down {
    margin-top: 115px;
}

.about-images__image--up {
    margin-top: -220px;
}

.about-disclamer {
    position: absolute;
    right: 27px;
    width: 397px;
    bottom: 80px;
}

.about-disclamer__text {
    margin-bottom: 30px;
}

.about-holder {
    position: relative;
}

/* About Page*/
.about__description {
    display: flex;
    justify-content: space-between;
}

.about__description-text {
    max-width: 530px;
}

.about__description-image {
    margin-right: 112px;
    margin-top: -119px;
    z-index: 2;
}

.about__description-text .title {
    margin-bottom: 20px;
}

.about__slider {
    margin-top: 123px;
}

.about__slider-item--image {
    height: 300px;
    width: 300px;
    border-radius: 300px;
    margin: 0 auto;
    background-size: cover;
    background-position: center;
}

.slide.about__slider-item {
    text-align: center;
    margin: 0 auto;
}

.about__slider-item--title {
    margin-top: 25px;
    font-size: 25px;
    line-height: 125%;
    text-align: center;
    color: #363538;
    font-family: 'Raleway Black', sans-serif;
    text-transform: uppercase;
}

.about__vacancies {
    display: flex;
    margin-top: 176px;
    justify-content: space-between;
    margin-bottom: 136px;
}

.about__vacancies-text {
    max-width: 535px;
    margin-top: 57px;
}

.about__vacancies-text .title {
    margin-bottom: 20px;
}

.about__vacancies-text .text {
    margin-bottom: 30px;
}

.about__description {
    position: relative;
}

img.about__description--cherry {
    position: absolute;
    left: 400px;
    top: 240px;
}

.about__vacancies-text {
    position: relative;
}


img.about__vacancies-text--lists {
    position: absolute;
    top: -76px;
    left: -100px;
}

img.about__vacancies-text--broccoli {
    position: absolute;
    top: 320px;
    left: 352px;
}


.about__vacancies-text .button {
    width: 250px;
}

.about__slider-holder {
    position: relative;
}

.about__slider--arrow {
    position: absolute;
    top: -119px;
    z-index: 10;
    left: 50px;
}

.about__slider--arrow span {
    position: absolute;
    font-size: 36px;
    width: 200px;
    top: -42px;
    left: 144px;
}

.about__slider--arrow.reveal-from-left {
    transition: all .5s;
    animation: reveal-from-left-animation 0.7s ease;
}

.about-images__image about-images__image--up img {
    border-radius: 10px;
}