.faq-holder {
    display: flex;
}

.faq-list {
    margin-top: 58px;
    min-width: 500px;
    margin-right: 135px;
}

.faq-list .accordeon {
    margin-top: 20px;
}

.faq-feedback {
    width: 100%;
}
