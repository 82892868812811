.personal__title {
    margin-top: -18px;
    display: flex;
    justify-content: flex-end;
    margin-right: 130px;
}

.personal__info {
    display: grid;
    margin-top: 40px;
    grid-template-columns: 312px 424px 537px;
    grid-gap: 24px;
}

.personal-card {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 5.5px 5.5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 30px;
    box-sizing: border-box;
    z-index: 1;
}

.personal-card__title {
    font-family: "Raleway Black", sans-serif;
    font-size: 25px;
    line-height: 125%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(54, 53, 56, 0.5);
}

.personal-card__body {
    margin: 20px 0;
}

.personal-card--support .button {
    margin-top: 20px;
}

.personal-card--support {
    background: #E2E8D4;
}

.personal__orders {
    margin-top: 25px;
}

.personal__data-input--label {
    font-size: 20px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    font-weight: bold;
    width: 110px;
}

.personal__data-input--value {
    font-size: 17px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    width: 200px;
    overflow: hidden;
    box-shadow: white;
}

.personal__data-input--action {
}

.personal__data-input > div {
    display: inline-block;
    vertical-align: middle;
}

.personal__data-input + .personal__data-input {
    margin-top: 28px;
}

.personal__order-number {
    display: flex;
    flex-direction: column;
}

.personal__order-title {

    display: flex;
    justify-content: space-between;
    margin: 40px 0 50px;
}

.personal__order-title span:nth-child(1) strong {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin-right: 10px;
}

.personal__order-title span:nth-child(2) {
    margin-top: 10px;
    font-size: 13px;
    line-height: 17px;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

.personal__order-product + .personal__order-product {
    margin-top: 35px;
}

.personal__order-product {
    display: grid;
    grid-template-columns: 35% 20% 25% 20%;

}

.personal__order-product--col {
    display: flex;
}

.personal__order-product--col:last-child {
    justify-self: flex-end;
}

.personal__order-products {
    width: 70%;
}

.personal__order-summary {
    display: flex;
    margin-top: 55px;
    justify-content: space-between;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #363538;
    width: 70%;
}

.personal__order-payment {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    width: 70%;
}

.personal__order span.react-loading-skeleton {
    min-height: 20px;
    width: 150px;
    margin: 0 10px;
}

.personal__order-summary--value {
    display: flex;
}

.personal__order-bottom {
    margin-top: 40px;
    width: 100%;
    height: 1px;
    background: rgba(54, 53, 56, 0.5);

}


.skeleton-title .react-loading-skeleton {
    height: 50px;
    width: 460px;
}

.skeleton-title-name .react-loading-skeleton  {
    height: 50px;
    width: 320px;
}

.personal__exit {
    position: absolute;
    right: 38px;
    top: -56px;
    background: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: none;
}

.personal__exit.show {
    display: block;
}

.personal-page.container {
    position: relative;
}