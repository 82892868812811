.cart__inner {
    display: flex;
    margin-top: 70px;
}

.cart__checkout {
    min-width: 421px;
    height: 100%;
    width: 421px;
    background: #FFFFFF;
    box-shadow: 0 5.5px 5.5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 50px;
    box-sizing: border-box;
}

.cart__products {
    width: 100%;
    margin-right: 28px;

    position: -webkit-sticky;
    position: sticky;
    top: 10px;
    align-self: flex-start;
}

.cart_product-card + .cart_product-card {
    margin-top: 28px;
}

.cart_product-card {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2), 4px 4px 4px rgba(255, 255, 255, 0.3);
    border-radius: 10px;
}

.cart_product-card__image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-width: 435px;
    border-radius: 10px 0 0 10px;
}

.cart_product-card__info {
    width: 100%;
    padding: 50px 30px 23px;
    display: flex;
    flex-direction: column;
    height: 260px;
    box-sizing: border-box;
}

.cart_product-card__title span {
    font-size: 25px;
    line-height: 125%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    font-family: 'Raleway Black', sans-serif;
    height: 60px;
    text-transform: uppercase;
}

.cart_product-card__option {
    margin-top: 20px;
    margin-bottom: 31px;
    height: 25px;
    opacity: 0.5;
}

.cart_product-card__bottom {
    display: flex;
    justify-self: flex-end;
    justify-content: space-between;
    align-items: flex-end;
}

.cart_product-card__counter {
    opacity: 0.4;
    display: flex;
    border-radius: 3px;
    border: 1px solid;
    height: 35px;
    align-items: center;
    font-size: 28px;
    text-transform: uppercase;
    color: #363538;
    width: 82px;
}

.cart_product-card__price--label {
    font-size: 17px;
    line-height: 23px;
    text-align: right;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    opacity: 0.4;
    margin-bottom: 10px;
}

.cart_product-card__price--value {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 21px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
}

.cart_product-card__price {
    padding: 5px 15px 5px 0;
}

.cart_product-card__counter-button {
    text-align: center;
    cursor: pointer;
    width: 100%;
    line-height: 0;
}

.cart_product-card__counter-value {
    box-sizing: border-box;
    font-size: 20px;
    line-height: 25px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    text-align: center;
    width: 100%;
}

.nav-buttons__item.cart-counter {
    position: relative;
}

.cart-counter--icon.active {
    display: flex;
}

.cart-counter--icon {
    border-radius: 30px;
    position: absolute;
    right: 0;
    width: 14px;
    height: 14px;
    bottom: 2px;
    justify-content: center;
    align-items: center;
    background: #BF4A26;
    border: 1.22727px solid #FFFFFF;
    box-sizing: border-box;
    color: #fff;
    display: none;
}

.cart-counter--count {
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #fff;
    font-weight: bold;
}

.checkout__title {
    font-weight: 900;
    font-size: 25px;
    line-height: 125%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #92B759;
    text-transform: uppercase;
    font-family: 'Raleway Black', sans-serif;
}

.checkout__form {
    margin-top: 50px;
}

.checkout__details--input input {
    width: 100%;
    height: 38px;
    border: none;
    border-bottom: 1px solid rgba(54, 53, 56, 0.40);
    font-size: 17px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    box-sizing: border-box;
    padding: 0 0 10px;
}

.checkout__details--input input::placeholder {
    color: rgba(54, 53, 56, 0.40);
    font-size: 17px;
    line-height: 23px;
    font-family: 'Raleway', sans-serif;
}

.checkout__details--input + .checkout__details--input {
    margin-top: 50px;
}

.checkout__label {
    font-family: "Raleway Black", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 19px;
    margin-bottom: 25px;
}

.checkout__delivery {
    margin-top: 50px;
}

.delivery-time__item--icon,
.payment-type__item--icon,
.pickup-type__item--icon {
    display: flex;
    align-items: center;
}

.payment-type__item,
.delivery-time__item,
.pickup-type__item {
    cursor: pointer;
    width: 100%;
    display: flex;
    font-size: 17px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    align-items: center;
}

.delivery-time__item + .delivery-time__item {
    margin-top: 13px;
}

.delivery-time__item--icon {
    margin-right: 10px;
}

.checkout__delivery-pickup {
    margin-top: 50px;
}

.checkout__payment {
    margin-top: 45px;
}

.payment-type {
    display: flex;
    justify-content: space-between;
}

.payment-type__item--icon {
    margin-right: 10px;
}

.payment-type__item + .payment-type__item {
    margin-left: 25px;
}

.pickup-type {
    display: flex;
    justify-content: space-between;
}

.pickup-type__item--icon {
    margin-right: 10px;
}

.pickup-type__item + .pickup-type__item {
    margin-left: 25px;
}

.checkout__agree {
    margin-top: 20px;
}
.checkout__confirm {
    margin-top: 50px;
}

.checkout__agree,
.checkout__confirm {
    display: flex;
}

.checkout__agree-checkbox, .checkout__confirm-checkbox {
    height: 30px;
}

.checkout__agree-text, .checkout__confirm-text {
    margin-left: 15px;
    display: flex;
    align-items: center;
}

.checkout__confirm--input {
    margin-top: 20px;
}

.checkout__confirm-text span {
    font-size: 14px;
    line-height: 23px;
}

.checkout__agree-text a,
.checkout__confirm-text a {
    font-size: 14px;
    line-height: 23px;
    color: rgba(54, 53, 56, 0.50);
}

.checkout__sum {
    margin-top: 50px;
    border-top: 1px solid rgba(54, 53, 56, 0.4);
    padding: 50px 0 30px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.checkout__sum-label {
    font-size: 19px;
    color: #363538;
    font-family: "Raleway Black", sans-serif;
}

.checkout__sum-value {
    font-size: 26px;
    font-weight: bold;
    text-align: right;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    margin-top: -3px; /* WTF?????????*/
}

.checkout__agree-checkbox,
.checkout__confirm-checkbox {
    position: relative;
}

#checkout-agree {
    height: 1px;
    width: 1px;
    top: 24px;
    left: 12px;
    position: absolute;
}

.cart-modal {
    right: 35px;
    top: 100px;
    width: 525px;
    border-radius: 10px;
    padding: 30px;
    position: absolute;
}

.cart-modal.modal--open {
    animation: unset;
}

.cart-modal__title {
    font-family: 'Raleway Black', sans-serif;
    font-size: 25px;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

.cart-modal__products {
    margin-top: 20px;
}

.cart-modal .cart_product-card {
    width: 100%;
    box-shadow: unset;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(54, 53, 56, 0.4);
    border-radius: unset;
    padding-bottom: 17px;
}

.cart-modal .cart_product-card__image {
    min-width: 232px;
    border-radius: 10px;
}

.cart-modal .cart_product-card__info {
    padding: 10px 10px 10px 20px;
    height: initial;
}

.cart-modal .cart_product-card__title span {
    font-size: 19px;
    line-height: 125%;
}

.cart-modal .cart_product-card__option {
    margin: 0;
    height: 15px;
    margin-bottom: 10px;
}

.cart-modal .cart_product-card__price {
    padding: 0;
}

.cart-modal .cart_product-card__price--value {
    font-size: 16px;
    text-align: right;
}

.cart-modal .cart_product-card__price--label {
    font-size: 13px;
    line-height: 17px;
    text-align: right;
    margin-bottom: unset;
}

.cart-modal .cart_product-card + .cart_product-card {
    margin: inherit;
}

.cart-modal .cart-modal__summary {
    width: 75%;
    float: right;
}

.cart-modal__summary-title {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
}

.cart-modal__summary-title--label {
    font-size: 25px;
    line-height: 125%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    font-family: 'Raleway Black', sans-serif;
    text-transform: uppercase;
}

.cart-modal__summary-title--value {
    font-size: 26px;
    line-height: 31px;
    text-align: right;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    font-weight: bold;
}

.cart-modal__summary .button {
    text-decoration: none;
}

.cart__sauces {
    margin-top: 150px;
}

.cart__sauces .product-cards-slider {
    width: auto;
    display: flex;
}

.cart__recommendations {
    margin-top: 170px;
}

.cart__recommendations .product-card {
    min-width: 400px;
}

.payment-type__item--disclaimer,
.delivery-time__item--disclaimer {
    margin-top: 15px;
    display: block;
    font-size: 12px;
}

.delivery-time__item--disclaimer {
    margin-bottom: 20px;
}

.persons-counter {
    display: flex;
    padding: 8px 0;
    border: 1px solid;
    width: 90px;
    border-radius: 5px;
    margin-top: 15px;
}

.persons-counter > div {
    width: 30px;
    text-align: center;
    cursor: pointer;
}
