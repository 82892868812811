.vacancies-page__description {
    width: 415px;
}

.vacancies-page__description .text {
    margin-top: 20px;
}

.vacancies-list {
    margin-top: 180px;
    position: relative;
}

.vacancies-list .accordeon-item .accordeon-item__description {
    padding: 0px 30px;
    margin: 0;
}

.vacancies-list .accordeon-item.accordeon-item--opened .accordeon-item__description {
    max-height: inherit;
    padding: 20px 30px;
}

.vacancies-list .accordeon-item {
    padding: 0 65px;
    margin: 35px 0;
}

.vacancies-list .accordeon-item + .accordeon-item {
    border-top: 1px solid rgba(54, 53, 56, 0.51);
    padding-top: 35px;
    margin-top: 0;
}

.vacancy-outer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.vacancy-title {
    font-size: 25px;
    line-height: 125%;
    font-family: 'Raleway Black', sans-serif;
    text-transform: uppercase;
}

.vacancies-list .accordeon-item__title {
    width: 100%;
}

.vacancies-list .accordeon-item__title-text {
    width: 100%;
}

.vacancies-list__arrow {
    position: absolute;
    left: 406px;
    top: -84px;
    display: flex;
}

.vacancies-list__arrow .text--handwritten {
    font-size: 55px;
    top: -90px;
    left: 220px;
    position: absolute;
    width: 350px;
}

.vacancy-advantages {
    background: #FFFFFF;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.2), 4px 4px 4px rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    padding: 30px;
    min-width: 330px;
    max-width: 330px;
    margin: 11px;
    box-sizing: border-box;
    max-height: 480px;
}

.vacancy-inner {
    display: flex;
    justify-content: space-between;
}

.vacancy-requirements {
    width: 100%;
    margin-left: 215px;
}

.vacancies-list .accordeon-item__description {
    width: 100%;

}

.vacancy-title {
    margin-left: 15px;
}

img.vacancy-advantages-shpinat1 {
    position: absolute;
    left: -45px;
    top: -18px;
}

.vacancy-advantages {
    position: relative;
}

img.vacancy-advantages-shpinat2 {
    position: absolute;
    left: 300px;
    top: 410px;
}

.vacancy-advantages__title.text--handwritten {
    font-size: 37px;
    line-height: 52px;
}

ol.vacancy-advantages__item {
    padding: 0 30px;
}

.vacancy-advantages__list {
    margin-top: 30px;
    padding: 0 30px;
}

li.vacancy-advantages__item {
    margin-top: 22px;
}

.vacancy-requirements__title {
    font-size: 26px;
    margin: 20px 0;
    line-height: 31px;
    text-transform: uppercase;
    font-weight: bold;
}

.vacancy-requirements__description ul {
    padding: 0 15px;
}

.vacancies-list .accordeon-item--opened.accordeon-item.accordeon-item--opened .vacancy-button {
    display: none;
}

.vacancy-inner .button {
    margin-top: 40px;
}


/* Form */

.vacancies-form {
    display: flex;
}

.vacancies-form__inner {
    padding: 100px 75px;
    background: rgb(225 231 212);
    border-radius: 0 10px 10px 0px;
}

.container.vacancies-page__form {
    margin-top: 180px;
}

.vacancies-form__text.text.text--faded {
    margin-top: 20px;
}

.vacancies-form__form {
    margin-top: 30px;
    width: 79%;
}

.vacancies-form__form-row {
    display: flex;
    margin-bottom: 10px;
}

.vacancies-form__form-row > .input {
    width: 100%;
}

.vacancies-form__form-row .input + .input {
    margin-left: 10px;
    margin-top: unset;
}

.vacancies-form__form-row > .input--textarea {
    height: 85px;
    resize: none;
}

.vacancies-form__attach {
    display: flex;
    margin-top: 25px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    align-items: center;
    cursor: pointer;
}

.vacancies-form__attach--icon {
    padding: 0 10px;
}

.vacancies-form__button {
    margin-top: 25px;
}

.vacancy-modal {
    padding: 30px;
    text-align: center;
}

.vacancy-modal .vacancies-form__form {
    text-align: center;
    margin: 0 auto;
    padding: 20px 0;
}

.vacancy-modal .vacancies-form__form .input {
    background: #f1f1f1;
}

.vacancy-modal {
    width: 950px;
}

.vacancies-list__arrow.reveal-from-right {
    transition: all .5s;
    animation: reveal-from-right-animation 0.7s ease;
}

.vacancies-form__image {
    flex-shrink: 0;
}

.vacancies-form__image img {
    height: 100%;
}