.container.five-rules {
    margin-top: 145px;
}

.five-rules .title-holder {
    display: flex;
    margin-left: 110px;
}

.five-rules .title-holder .number {
    margin-right: 40px;
}

.five-rules .five-rules-banner {
    height: 900px;
    margin-bottom: 75px;
}

.five-rules__step {
    width: 300px;
}

.five-rules__step_text {
    font-size: 13px;
    line-height: 17px;
    margin-top: 4px;
    color: #363538;
}

.five-rules__step_title {
    font-size: 36px;
    line-height: 50px;
}

.five-rules__step {
    position: absolute;
}

.five-rules__step.five-rules__step--one {
    top: 68px;
    left: 224px;
}

.five-rules-banner {
    position: relative;
    opacity: 0;
}

.five-rules-banner.active {
    opacity: 1;
}

.five-rules__step_arrow {
    position: absolute;
}

.five-rules__step.five-rules__step--two {
    left: 784px;
    top: 68px;
}

.five-rules__step.five-rules__step--three {
    top: 444px;
    left: 896px;
}

.five-rules__step.five-rules__step--four {
    left: 672px;
    top: 676px;
}

.five-rules__step.five-rules__step--five {
    top: 376px;
    left: 112px;
}

.five-rules__step--one .five-rules__step_arrow {
    top: 109px;
    left: 99px;
}

.five-rules__step--two .five-rules__step_arrow {
    top: -24px;
    left: -159px;
}

.five-rules__step--three .five-rules__step_arrow {
    top: -185px;
    left: -68px;
}

.five-rules__step--four .five-rules__step_arrow {
    top: -142px;
    left: 54px;
}

.five-rules__step--five .five-rules__step_arrow {
    top: 78px;
    left: 189px;
}

.five-rules-image__pibimpab {
    position: absolute;
    left: 457px;
    top: 151px;
}

.five-rules-image__sticks {
    position: absolute;
    left: 237px;
    top: 408px;
}

.five-rules-image__sause {
    top: 591px;
    left: 495px;
    position: absolute;
}


.five-rules-banner.active .five-rules__step--one .reveal {
    animation: reveal-animation--one 1s ease;
}

.five-rules-banner.active .five-rules__step--two .reveal {
    animation: reveal-animation--two 1s ease;
}

.five-rules-banner.active .five-rules__step--three .reveal {
    animation: reveal-animation--three 1s ease;
}

.five-rules-banner.active .five-rules__step--four .reveal {
    animation: reveal-animation--four 1s ease;
}

.five-rules-banner.active .five-rules__step--five .reveal {
    animation: reveal-animation--five 2s ease;
}

.five-rules-banner.active .reveal-circle {
    transition: all .5s;
    animation: reveal-circle-animation 0.7s ease;
}


.five-rules-banner.active .reveal-from-left {
    transition: all .5s;
    animation: reveal-from-left-animation 0.7s ease;
}


.five-rules-banner.active .reveal-from-right {
    transition: all .5s;
    animation: reveal-from-right-animation 0.7s ease;
}

