
.desktop {
    display: block;
}

.mobile {
    display: none;
}

@media (min-width: 1820px) {
    .main, .page {
        background-position: left -100px;
    }

    .page-vacancies {
        background-position: left -100px, 77% 112px, left -100px !important;
    }

    .page-success {
        background-position: left -100px, 57% 112px, left -100px !important;
    }
}

@media (max-width: 768px) {
    .main-banner__image {
        min-height: 556px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .parallax-image {
        display: none;
    }

    /* ui */
    body {
        padding-right: initial !important;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .loader {
        padding: 150px 0;
    }

    .container.container-pd.back {
        z-index: 220;
        margin-top: -65px;
    }

    .clearfix {
        height: 60px;
    }

    .checkout__title {
        font-size: 55px;
        line-height: 65px;
    }

    .back__text {
        font-size: 20px;
        line-height: 23px;
    }

    .main, .page {
        background-image: url('../green_mob.svg');
    }

    .container {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .nav {
        width: 100%;
        padding: 30px 20px;
        display: inherit;
        box-sizing: border-box;
        z-index: 21;
    }

    .nav-logo {
        margin-top: 0;
    }

    .nav-row {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    .nav-logo img {
        width: 48px;
    }

    .nav-phone {
        font-weight: 900;
        font-size: 25px;
        line-height: 125%;
        font-family: 'Raleway Black', sans-serif;
        padding: 0;
    }

    .nav-menu-toggler {
        margin-left: 45px;
    }

    .nav-buttons {
        flex-direction: column;
        align-items: flex-end;
        margin-top: 60px;
    }

    .nav-buttons__item + .nav-buttons__item {
        margin-top: 47px;
    }

    .nav-buttons__item img {
        width: 35px;
        height: 35px;
    }

    span.nav-phone-icon {
        margin-right: 22px;
    }

    .checkout__order-sum.mobile {
        display: flex;
        align-items: center;
    }

    .checkout__order-sum.mobile .button {
        width: 100%;
        margin-left: 90px;
    }

    .checkout__sum-label {
        font-size: 23px;
        line-height: 32px;
        font-family: 'Raleway', sans-serif;
    }

    .checkout__sum-value {
        margin-top: 5px;
        font-size: 36px;
        line-height: 42px;
    }

    .checkout__order-sum.mobile .button span {
        font-size: 23px;
        padding: 10px;
    }

    .nav-inner {
        z-index: 200;
        position: fixed;
        display: block;
        width: 55%;
        height: 100%;
        top: 0;
        right: 0;
        background: #fff;
        border-radius: 40px 0 0 0;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2), 4px 4px 4px rgba(255, 255, 255, 0.3);
        transition: all .5s;
        transform: translateX(100%);

    }

    .nav-inner.show {
        transform: translateX(0%);
    }

    .nav-menu {
        display: block;
        width: initial;
        padding: 95px 20px;
    }

    a.nav-menu__item {
        display: block;
        text-align: left;
        color: #363538;
        font-family: 'Raleway', sans-serif;
        font-size: 27px;
        padding: 19px 0;
        font-weight: bold;
        box-sizing: border-box;
        margin: 0 10px;
        width: 93%;
    }

    .nav {
        position: relative;
    }

    a.nav-menu__item + a.nav-menu__item {
        border-top: 1px solid rgba(54, 53, 56, 0.4);
    }

    span.nav-phone-icon img {
        height: 25px;
    }

    .button {
        white-space: nowrap;
    }

    /* Catalog */
    .container.catalog {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .zone-dropdown-value-icon--caret {
        width: 45px;
    }

    .zone-dropdown {
        padding: 14px 22px 14px 10px;
    }

    .sort-dropdown .dropdown-value-icon {
        margin-left: 0;
    }

    .catalog-holder {
        display: inherit;
    }

    .catalog-menu {
        max-width: 70%;
    }

    .catalog-menu__categories--item + .catalog-menu__categories--item {
        border-top: 1px solid #ccc;
    }

    .catalog-menu__categories--item {
        font-size: 18px;
    }

    .catalog-menu__categories--item a {
        font-weight: bold;
        font-size: 24px;
        margin: 22px 0;
    }

    .catalog-menu__categories--item span {
        font-size: 22px;
        opacity: 0.5;
    }

    .catalog-filters {
        margin-top: 30px;
    }

    .catalog-filters__filter {
        padding: 0 11px;
        white-space: nowrap;
    }

    .catalog-filters__sort {
        /*margin-right: 20px;*/
    }

    .catalog-filters__hit {
        margin-right: 20px;
    }

    .catalog-filters__hit span {
        line-height: 32px;
    }

    .product-card.product-card--small {
        width: 100%;
    }

    .catalog-products {
        margin-top: 35px;
        grid-template-columns: 50% 50%;
        grid-gap: 20px;
        width: calc(100% - 20px);
    }

    .product-card--small .product-card__picture {
        height: 160px;
    }

    .product-card--small .product-card__body-footer {
        display: flex;
        align-items: center;
    }

    .product-card--small .product-card__body-footer_button {
        width: 100%;
        min-width: initial;
        max-width: initial;
        margin-top: 20px;
        order: 2;
    }

    .product-card--small .product-card__body-footer_price {
        font-size: 18px;
        line-height: 21px;
        text-transform: uppercase;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #363538;
    }

    .product-card--small .product-card__body-footer_weight {
        line-height: 17px;
        font-size: 13px;
        display: flex;
        align-items: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #363538;
        order: 1;
        width: inherit;
    }

    .product-card__body-footer_price {
        order: 0;
    }

    .product-card--small .product-card__body-title .icon img {
        height: 15px;
    }

    .product-card--small .product-card__body-title .icon {
        padding: 2px 5px;
    }

    .catalog-filters {
        overflow-x: scroll;
        width: calc(100% + 20px);
        padding-bottom: 2px;
        margin-bottom: -2px;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .catalog-filters::-webkit-scrollbar {
        display: none;
        width: 0 !important
    }

    .catalog-filters__filter:last-child {
        margin-right: 20px;
    }

    .catalog-buttons {
        display: flex;
        position: sticky;
        bottom: 20px;
        width: 100%;
        z-index: 3;
    }

    .catalog-buttons .button {
        width: 100%;
        z-index: 10;
    }

    .catalog-buttons .button + .button {
        margin: 0;
        margin-left: 20px;
    }

    .catalog-buttons--blur {
        position: absolute;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        background: #ffffff52;
        filter: blur(5px);
        padding: 20px;
        left: -20px;
        top: -20px;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    .catalog-holder {
        margin-bottom: 30px;
    }

    .catalog-menu__categories--items {
        padding: 70px 40px;
    }

    a.catalog-menu__categories--item {
        margin: 0;
        padding: 20px 0;
        border-bottom: 1px solid rgba(54, 53, 56, 0.4);
    }

    /* Footer */
    .footer-col:nth-child(1) {
        display: block;
        width: 100%;
    }

    .footer-col:nth-child(2) {
        display: block;
        width: 100%;
    }

    .footer .container {
        display: block;
        padding: 60px 55px;
    }

    .footer {
        height: 100%;
        display: block;
    }

    .container.footer-links {
        display: flex;
    }

    .footer-logo img {
        width: 55px;
    }

    .footer-menu {
        margin: 0;
        margin-left: 50px;
    }

    .footer-menu__col-item + .footer-menu__col-item {
        margin-top: 32px;
    }

    a.footer-menu__col-item {
        font-size: 22px;
    }

    a.footer-menu__col-item.footer-menu__col-item--phone {
        font-size: 20px;
        font-weight: bold;
    }

    .footer-icons {
        flex-direction: row;
        margin-top: 12px;
    }

    .footer-copyright {
        flex-direction: row;
        height: inherit;
        margin-top: 35px;
    }

    /* Modals */
    .zone-modal__title .title {
        font-size: 30px;
        line-height: 30px;
    }

    .zone-modal__title {
        padding: 70px 0 40px 0;
        /*border-bottom: 1px solid rgba(54, 53, 56, 0.4);*/
    }

    .zone-modal__sheet {
        margin: 50px;
    }

    .zone-modal__inner {
        display: block;
        box-shadow: unset;
        border-radius: unset;
        padding: 0;
        margin: 50px 0 0 0;
    }

    .zone-modal__item + .zone-modal__item {
        margin-top: 25px;
    }

    .zone-modal__item-text--link {
        display: none;
    }

    .zone-modal__button {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .zone-modal__button .button {
        padding: 19px 30px;
    }

    .zone-modal__button .button span {
        font-size: 23px;
        line-height: 27px;
    }

    /* Action Modal */
    .action-modal__selector {
        display: none;
    }

    .action-modal__inner {
        display: block;
    }

    .action-modal {
        min-width: inherit;
    }

    .action-modal [data-rsbs-header] {
        margin-top: -30px;
    }

    .action-modal__button {
        display: none;
    }

    .action-modal__image {
        min-width: unset;
        width: 100%;
        height: 390px;
        border-radius: 16px 16px 0 0;
        min-height: unset;
    }

    .action-modal__content {
        padding: 50px 20px;
        box-sizing: border-box;
        min-width: inherit;
    }

    .action-modal__description {
        font-size: 23px;
        line-height: 32px;
    }

    .action-modal__disclamer {
        font-size: 20px;
        line-height: 25px;
    }

    /* Product modal */
    .product-modal__selector {
        display: none;
    }

    .product-modal__inner {
        display: block;
    }

    .product-modal {
        min-width: inherit;
    }

    .product-modal__image {
        min-width: unset;
        width: 100%;
        height: 390px;
        border-radius: 35px 35px 0 0;
    }

    .product-modal [data-rsbs-header] {
        margin-top: -30px;
    }

    .product-modal__content {
        padding: 50px 20px;
        padding-bottom: 25px;
        min-width: inherit;
        width: 100%;
    }

    .product-modal__title {
        font-size: 45px;
        line-height: 53px;
    }

    .product-modal__description {
        font-size: 23px;
        line-height: 32px;
        padding: 30px 0 10px;
    }

    .product-modal__weight, .product-modal__ingredients, .product-modal__nutrition {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 15px;
    }

    .product-modal__price {
        font-size: 30px;
        line-height: 35px;
    }

    .product-modal__buy  {
        margin-top: 0;
    }

    .product-modal__buy .button span {
        font-size: 23px;
        line-height: 32px;
    }

    /* Cart */
    .cart-modal__summary {
        display: none;
    }

    .cart-modal-sheet {
        padding: 80px 20px;
    }

    .cart-modal__title {
        font-size: 40px;
        line-height: 47px;
    }

    .cart_product-card__image {
        min-width: 240px;
        max-height: 165px;
        border-radius: 15px;
    }

    .cart_product-card {
        box-shadow: unset;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(54, 53, 56, 0.4);
        border-radius: unset;
    }

    .cart_product-card__info {
        padding: 0;
        margin-left: 25px;
        height: 100%;
    }

    .cart_product-card__option {
        margin-top: 10px;
        margin-bottom: 10px;
        height: 23px;
    }

    .cart_product-card + .cart_product-card {
        padding-top: 30px;
    }

    .cart-modal-sheet__summary {
        display: flex;
    }

    .cart-modal-sheet__summary .button {
        width: 100%;
        margin-left: 100px;
    }

    .cart-modal-sheet__summary-title {
        width: 100px;
    }

    .cart-modal-sheet__summary-title--value {
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 42px;
        text-align: right;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #363538;
    }

    .cart-modal__sauces {
        border-bottom: 1px solid rgba(54, 53, 56, 0.4);
        padding-bottom: 30px;
    }

    .cart-modal__sauces .title span {
        font-size: 25px;
        line-height: 125%;
    }

    .cart-sauces__slider {
        display: flex;
        overflow-y: hidden;
        overflow-x: auto;
        padding: 20px 0;
    }

    .cart-sauces__slider::-webkit-scrollbar {
        width: 0 !important;
    }

    .cart-sauce {
        min-width: 46.7%;
    }

    .cart-sauce__title .text {
        /* font-size: 20px; */
    }

    .cart-sauce__image {
        height: 135px;
        background-position: center;
        background-size: cover;
        border-radius: 10px;
    }

    .cart-sauce + .cart-sauce {
        margin-left: 25px;
    }

    .cart-sauce__title {
        margin-top: 10px;
    }

    .cart-sauce__footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .cart-sauce__price_value {
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 21px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #363538;
    }

    .cart-sauce__price_label {
        font-size: 17px;
        line-height: 23px;
        text-align: right;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #363538;
        opacity: 0.4;
        margin-bottom: 10px;
    }

    .cart-sauce__button {
        width: 120px;
    }

    .cart-modal__recommendations .button span,
    .cart-sauce__button span {
        font-size: 54px;
        font-weight: normal;
    }

    .cart-modal-sheet__summary-title--label {
        font-style: normal;
        font-weight: normal;
        font-size: 23px;
        line-height: 32px;
        font-feature-settings: 'pnum' on, 'lnum' on;
    }

    .cart-modal__recommendations .product-cards-slider {
        overflow: auto;
        padding: 10px 5px;
    }

    .cart-modal__recommendations .product-card {
        min-width: 311px !important;
        max-width: 311px !important;
        margin-right: 25px;
    }

    .cart-modal__recommendations .title span,
    .cart-modal__sauces .title span {
        font-size: 25px;
        line-height: 125%;
    }

    /* Auth modal*/
    .auth-modal__title {
        font-size: 45px;
        line-height: 53px;
    }

    .auth-modal__inner {
        padding: 70px 20px;
    }

    .auth-modal__subtitle {
        font-size: 22px;
    }

    .auth-modal__body-input input::placeholder {
        font-size: 18px;
    }

    .agreement__confirm-text a {
        font-size: 16px;
    }


    .auth-modal__footer {
        margin: 45px auto;
        width: 90%;
    }

    /** Checkout */
    .checkout__details--input {
        width: 80%;
    }

    .checkout__details--input input {
        background: transparent;
        font-size: 23px;
        line-height: 32px;
    }

    .checkout__details--input input::placeholder {
        font-size: 23px;
        line-height: 32px;
    }

    form.checkout__form {
        margin-top: 100px;
    }

    .checkout__details--input + .checkout__details--input {
        margin-top: 75px;
    }

    .checkout__label {
        font-size: 25px;
        line-height: 125%;
        text-transform: uppercase;
    }

    .checkout__delivery {
        margin-top: 100px;
    }

    .delivery-time__item--icon img {
        width: 30px;
    }

    .payment-type__item, .delivery-time__item, .pickup-type__item {
    }

    .delivery-time__item--value {
        font-size: 23px;
        line-height: 32px;
    }

    .delivery-time__item + .delivery-time__item {
        margin-top: 25px;
    }

    .checkout__delivery-pickup {
        margin-top: 100px;
    }

    .checkout__payment {
        margin-top: 100px;
    }

    .payment-type__item--icon img {
        width: 30px;
    }

    .payment-type__item--value {
        font-size: 23px;
        line-height: 32px;
    }

    .payment-type {
        display: block;
    }

    .payment-type__item + .payment-type__item {
        margin-left: unset;
        margin-top: 25px;
    }

    .pickup-type__item--icon img {
        width: 30px;
    }

    .pickup-type__item--value {
        font-size: 23px;
        line-height: 32px;
    }

    .pickup-type {
        display: block;
    }

    .pickup-type__item + .pickup-type__item {
        margin-left: unset;
        margin-top: 25px;
    }

    .checkout__confirm {
        margin-top: 100px;
    }

    .checkout__agree {
        margin-top: 20px;
    }

    .checkout__agree-text,
    .checkout__confirm-text {
        margin-left: 25px;
    }

    .checkout__sum {
        margin-top: 100px;
    }

    .checkout__title span:first-child {
        color: #363538;
    }

    .checkout__order-sum.mobile {
        display: flex;
        align-items: center;

        margin-top: 100px;
        border-top: 1px solid rgba(54, 53, 56, 0.4);
        padding: 100px 0 30px;
        width: 100%;
    }

    .checkout__order-sum.mobile .button {
        width: 100%;
        margin-left: 90px;
    }

    .checkout__sum-label {
        font-size: 23px;
        line-height: 32px;
        font-family: 'Raleway', sans-serif;
    }

    .checkout__sum-value {
        margin-top: 5px;
        font-size: 36px;
        line-height: 42px;
    }

    .checkout__order-sum.mobile .button span {
        font-size: 23px;
        padding: 10px;
    }

    /* Restaurants */
    .restaurants-page__description, .restaurant-page__description {
        width: 80%;
    }

    .restaurants-page__description .text, .restaurant-page__description .text {
        font-size: 23px;
        line-height: 32px;
        margin-top: 17px;
    }

    .restaurants-page__map {
        margin-top: 90px;
        height: 635px;
    }

    .restaurants-page__list {
        margin-top: 170px;
    }

    .restaurants-list {
        display: flex;
        width: 100%;
        overflow-x: scroll;
    }

    .restaurants-list::-webkit-scrollbar {
        height: 1px;
    }

    .hits-link {
        margin-right: 0;
        width: 75%;
        float: right;
    }

    /* Actions */
    .actions-holder {
        flex-direction: row;
        overflow-y: scroll;
        flex-wrap: nowrap;
    }

    .actions-holder::-webkit-scrollbar {
        overflow-y: scroll;
    }

    .action-card {
        max-width: 100% !important;
        width: 100% !important;;
    }

    .action-card + .action-card {
        margin-left: 20px;
    }

    /* Contacts */
    .contacts__restaurants-list {
        flex-direction: column;
        margin-top: 46px;
    }

    .contacts__restaurants-grid {
        order: 1;
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        grid-gap: unset;
    }

    .contacts__restaurants-map {
        order: 0;
        height: 1px;
        width: calc(100% + 40px);
        padding: 0;
        margin-left: -20px;
        min-height: 635px;
        border-radius: unset;
        position: relative;
    }

    .restaurant-contact {
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2), 4px 4px 4px rgba(255, 255, 255, 0.3);
        border-radius: 15px;
        width: 100%;
        padding: 32px 20px 20px 20px;
        box-sizing: border-box;
        display: none;
    }

    .restaurant-contact--no-back {
        padding: 0;
        box-shadow: unset;
        border-radius: unset;
        background: unset;
    }

    .restaurant-contact.restaurant-contact--show {
        display: block;
    }

    .restaurant-contact > .text {
        font-size: 23px;
        line-height: 32px;
    }

    .restaurant-contact__link.text {
        font-size: 20px;
    }

    .restaurant-contact + .restaurant-contact {
        margin-top: 15px;
    }

    .contacts__restaurants--hide,
    .contacts__restaurants--show {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 420px;
        text-align: center;
        height: 70px;
        background: #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 23px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .contacts__restaurants--hide .icon,
    .contacts__restaurants--show .icon {
        margin-left: 25px;
        height: 35px;
    }

    .contacts__restaurants--show {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 420px;
        text-align: center;
        height: 70px;
        background: #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 23px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .contacts__restaurants--show .icon {
        margin-left: 25px;
        height: 35px;
    }

    .contacts__restaurants-list {
        position: relative;
        padding-bottom: 95px;
    }

    .contacts__feedback {
        margin-top: 0px;
        display: block;
    }

    .contacts__feedback-testimonial .title {
        margin-bottom: 48px;
    }

    .contacts__feedback-testimonial {
        width: 100%;
        min-width: initial;
        margin-right: 0;
    }

    .contacts__feedback-testimonial .text {
        margin-bottom: 50px;
        line-height: 34px;
    }

    .contacts__feedback-form {
        margin-top: 150px;
        width: calc(100% + 40px);
        margin-left: -20px;
    }

    .feedback-form {
        padding: 125px 75px;
    }

    .feedback-form__text {
        margin-top: 49px;
        width: 100%;
    }

    .feedback-form__form {
        width: 100%;
        margin-top: 32px;
    }

    .input {
        padding: 24px;
        border-radius: 15px;
        font-size: 16px;
    }

    .feedback-form__button {
        width: 100%;
        margin-top: 37px;
        font-size: 23px;
    }

    .contacts__feedback-testimonial .button span,
    .feedback-form__button span {
        font-size: 23px;
        padding: 6px;
    }

    /* Main page */
    .main-banner .inner {
        flex-direction: column;
        width: 100%;
    }

    .container.main-banner {
        margin-top: 40px;
    }

    .main-banner__text {
        width: 80%;
    }

    .subtitle.text--handwritten {
        align-self: flex-start;
        margin: 0;
    }

    .main-banner__text {
        margin-bottom: 10px;
    }

    .main-banner__card {
        width: 100%;
        max-width: 100%;
        padding: 10px 30px;
        box-sizing: border-box;
    }

    .card__title {
        font-size: 40px;
        line-height: 47px;
        font-family: 'Raleway Black', sans-serif;
        display: flex;
        margin-bottom: 25px;
    }

    .card {
        padding: 45px 40px;
    }

    .card__body .text {
        font-size: 23px;
        line-height: 32px;
        min-height: 96px;
    }

    .card__buttons {
        margin-top: 45px;
    }

    .card__buttons .button span {
        font-size: 23px;
        line-height: 35px;
    }

    .container.hits {
        margin: 260px 0 0 0;
    }

    .container.hits .title {
        width: 95%;
    }

    .container.hits .product-cards-slider {
        width: calc(100% + 20px);
        margin-bottom: 115px;
        overflow-y: hidden;
    }

    .product-cards-slider::-webkit-scrollbar {
        height: 1px;
    }

    .product-cards-slider {
        margin-top: 50px;
    }

    .product-cards-slider .product-card:not(.product-card--small) {
        min-width: 423px;
        margin-bottom: 10px;
    }

    .product-cards-slider .product-card:not(.product-card--small) .product-card__body-title .text {
        font-size: 30px;
        line-height: 35px;
    }

    .product-cards-slider .product-card:not(.product-card--small) .product-card__body-title {
        height: 80px;
    }

    .product-cards-slider .product-card:not(.product-card--small) .product-card__body-description .text {
        font-size: 23px;
        line-height: 30px;
        height: 60px;
    }

    .product-cards-slider .product-card:not(.product-card--small) .product-card__body-footer {
        margin-top: 35px;
    }

    .product-cards-slider .product-card:not(.product-card--small) .product-card__body-footer_price {
        font-size: 31px;
        padding: 0 15px;
    }

    .product-cards-slider .product-card:not(.product-card--small) + .product-card {
        margin-left: 40px;
    }

    .container.hits .hits-link a {
        justify-content: center;
    }

    .container.hits .hits-link a .text {
        font-size: 23px;
        line-height: 27px;
    }

    .five-rules .title {
        font-size: 50px;
    }

    .five-rules .title-holder {
        margin-left: 0;
    }

    .five-rules .title-holder .number img {
        height: 260px;
    }

    .container.five-rules {
        margin-top: 500px;
    }

    .five-rules-banner {
        padding: 165px 0 35px 0;
    }

    .five-rules__step_title.text--handwritten {
        font-size: 55px;
        line-height: 60px;
    }

    .five-rules__step {
        position: inherit;
        width: 55%;

    }

    .five-rules__step:nth-child(1) {
        width: 55%;
    }

    .five-rules__step:nth-child(2) {
        width: 40%;
    }

    .five-rules__step:nth-child(3) {
        width: 44%;
    }

    .five-rules__step:nth-child(3) {
        width: 42%;
    }

    .five-rules__step:nth-child(4) {
        width: 43%;
    }

    .five-rules__step:nth-child(5) {
        width: 55%;
    }

    .five-rules .five-rules-banner {
        height: 100%;
        margin-bottom: 105px;
    }

    .five-rules__step_text {
        font-size: 23px;
        line-height: 32px;
        margin-top: 10px;
    }

    .five-rules__step + .five-rules__step {
        margin-top: 50px;
    }

    .container.actions {
        margin-bottom: 100px;
    }

    .container.restaurants .title {
        width: 70%;
    }

    .container.restaurants {
        margin-bottom: 20px;
    }

    .restaurant-slider {
        max-width: inherit;
    }

    .restaurant-slider__holder {
        width: calc(100% + 40px);
        margin-left: -20px;
    }

    .restraurant-slider__item {
        width: 100%;
        height: 705px;
        border-radius: 0;
    }

    .restaurant-slider .slick-slider, .restaurant-slider .slick-list, .restaurant-slider .slick-track {
        height: 100%;
    }

    .restaurant-slider .dropdown-value {
        font-size: 23px;
        font-weight: normal;
        line-height: 30px;
    }

    .restaurant-slider .slider-arrow {
        margin: 0 100px;
    }

    .restaurant-slider-arrows {
        margin-top: 50px;
    }

    .restaurant-slider-selector .dropdown-inner {
        position: absolute;
        z-index: 10;
        left: 0;
        padding: 18px 25px;
        width: 70%;
        margin-top: 20px;
    }

    .restaurant-slider-selector .dropdown-item {
        padding: 15px 5px;
    }

    .restaurant-slider-selector .dropdown-radio img {
        width: 25px;
    }

    .restaurant-slider-selector .dropdown-item--active .dropdown-text {
        font-family: 'Raleway Black', sans-serif;
    }

    .restaurant-slider-selector .dropdown-text {
        font-size: 23px;
        line-height: 5px;
    }

    .restaurant-slider-selector .dropdown-radio {
        height: 26px;
    }

    .restaurant-slider-selector {
        position: relative;
        right: inherit;
        bottom: inherit;
        margin-bottom: 30px;
    }

    .container.testimonials {
        margin-top: 165px;
        flex-direction: column;
    }

    .testimonials__description .text {
        margin-top: 50px;
        font-size: 23px;
        line-height: 32px;
    }

    .testimonials__description {
        width: 100%;
        min-width: unset;
    }

    .testimonials__slider-holder {
        margin-left: unset;
        margin-top: 50px;
        width: calc(100% + 20px);
    }

    .testimonials__card {
        height: inherit;
        border-radius: 10px;
    }

    .testimonials__card-body_summary {
        height: 285px;
        font-size: 23px;
        line-height: 32px;
    }

    .testimonials__card-body_title {
        font-size: 30px;
        line-height: 35px;
    }

    .testimonials__card-footer_link {
        font-size: 17px;
    }

    .testimonials__slider-arrows {
        justify-content: space-around;
    }

    .about.container-fluid {
        margin: 298px 0 0;
        background: unset;
    }

    .about .container {
        padding: 0 0;
        height: inherit;
    }

    .about-title {
        min-width: inherit;
        width: 100%;
        background: #88B04B;
        padding: 90px 95px;
        margin: 0 auto;
        box-sizing: border-box;
        height: 478px;
    }

    .about-holder {
        display: block;
    }

    .about-disclamer {
        position: initial;
    }

    .about-images__image {
        margin: 0;
    }

    .about-disclamer {
        position: initial;
        width: 100%;
        padding: 75px 90px 130px;
        box-sizing: border-box;
    }

    .about-images__image {
        margin: 0;
    }

    .about-images__image.about-images__image--up {
        padding-bottom: 150px;
    }

    .about-images__image.about-images__image--down {
        padding-top: 150px;
    }

    .about-disclamer__text.text {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 15px;
    }

    a.about-disclamer__link.text.text--faded {
        font-size: 20px;
    }

    .container.faq {
        margin-top: 110px;
        margin-bottom: 0;
    }

    .faq-holder {
        flex-direction: column;
    }

    .faq-list .accordeon {
        margin-top: 53px;
    }

    .accordeon-item__title-text {
        font-size: 22px;
        font-weight: bold;
    }

    .accordeon-item__title-icon {
        margin-right: 17px;
    }

    .accordeon-item__description {
        font-size: 23px;
        line-height: 32px;
    }

    .accordeon-item.accordeon-item--opened .accordeon-item__description {
        max-height: 250px;
        margin-top: 23px;
        margin-left: 40px;
        margin-bottom: 10px;
    }

    .faq-list {
        min-width: inherit;
        margin-right: unset;
    }

    .accordeon-item + .accordeon-item {
        margin-top: 40px;
    }

    .faq-feedback {
        margin-top: 175px;
        width: calc(100% + 40px);
        margin-left: -20px;
    }

    .faq-feedback .feedback-form {
        padding: 145px 95px;
        border-radius: 0;
    }

    .faq-feedback .feedback-form__text.text.text--faded {
        font-size: 23px;
        line-height: 32px;
        margin-top: 32px;
    }

    .faq-feedback .feedback-form__button {
        margin-top: 15px;
    }

    .about__description-text {
        max-width: inherit;
    }

    .about__description {
        display: block;
    }

    .about__description-image {
        margin-right: inherit;
        margin-top: 85px;
        margin-left: -20px;
    }

    .about__description-text .title {
        width: 80%;
    }

    .about__description-text .text {
        line-height: 34px;
        margin-top: 45px;
        width: 65%;
    }

    .about__slider {
        display: flex;
        margin-top: 298px;
        overflow-x: scroll;
        width: calc(100% + 20px);
    }

    .about__slider::-webkit-scrollbar {
        height: 1px;
    }

    .about__slider-item + .about__slider-item {
        margin-left: 50px;
    }

    .about__vacancies {
        margin-top: 160px;
    }

    .about__vacancies .text {
        font-size: 23px;
        line-height: 32px;
        margin-top: 50px;
    }

    .about__vacancies .button {
        margin-top: 50px;
    }

    .about__vacancies .button span {
        font-size: 23px;
        line-height: 32px;
        padding: 5px;
    }

    .not-found__image {
        margin-left: inherit;
    }

    .not-found {
        margin-top: 50px;
        margin-bottom: 100px;
        min-height: unset;
    }

    .personal__title {
        margin-top: 64px;
        margin-right: unset;
        justify-content: flex-start;
    }

    .personal__title .title {
        font-size: 40px;
        line-height: 46px;
    }

    .personal__title span {
        color: black !important;
    }

    .personal__bs-holder {
        margin-top: 75px;
        margin-bottom: 110px;
    }

    .personal__bs-button {
        background: #FFFFFF;
        /* тень карточки */
        box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.2), 4px 4px 4px rgba(255, 255, 255, 0.3);
        border-radius: 5px;
        display: flex;
        padding: 30px 40px;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
    }

    .personal__bs-button_title {
        font-size: 23px;
        line-height: 32px;
        font-weight: bold;
    }

    .personal__bs-button + .personal__bs-button {
        margin-top: 25px;
    }

    .personal__bs-button_icon img {
        width: 40px;
    }

    .personal__bs-button.personal__bs-button--green {
        background: #92B759;
    }

    .personal__bs-button.personal__bs-button--green .per {
    }

    .personal__bs-button.personal__bs-button--green .personal__bs-button_title {
        color: #fff;
    }

    .personal-card__bs {
        padding: 120px 60px;

    }

    .personal-card__body {
        margin-top: 50px;
    }

    .personal-card__body .button {
        margin-top: 30px;
    }

    .personal-card__body .text {
        font-size: 23px;
        line-height: 32px;
    }

    .personal-card__title {
        font-size: 30px;
        line-height: 35px;
        text-align: center;
        padding-bottom: 50px;
    }

    .personal-card.personal-card--orders {
        box-shadow: unset;
        padding: 0;
    }

    .personal__order-bottom {
        margin-top: 80px;
    }

    .personal__order-title {
        margin-top: 80px;
    }

    .search-modal {
        right: -20px;
        top: -10px;
    }

    .search-modal input {
        padding: 21px 100px 21px 0;
        border-radius: 10px 0 0 10px;
        text-align: right;
    }

    .nav-buttons__item.search-icon {
        position: relative;
    }

    .search-modal__button {
        display: block;
        position: absolute;
        right: 20px;
        top: 10px;
        background-color: transparent;
        padding: 0;
    }

    .search-modal__button img {
        display: block;
    }

    [data-rsbs-overlay] {
        z-index: 24 !important;
        border-top-left-radius: 45px !important;
        border-top-right-radius: 45px !important;
    }

    [data-rsbs-backdrop] {
        z-index: 23 !important;
    }

    [data-rsbs-header]:before {
        display: none !important;
    }

    [data-rsbs-header] {
        box-shadow: none !important;
    }

    .vacancies-page__description .text {
        margin-top: 50px;
        line-height: 34px;
    }

    .vacancy-advantages {
        width: 100%;
        min-width: initial;
        max-width: initial;
        padding: 45px;
        margin: 0;
        margin-bottom: 85px;
        max-height: 580px;
    }

    img.vacancy-advantages-shpinat1 {
        left: 8px;
        top: -48px;
    }

    img.vacancy-advantages-shpinat2 {
        top: 555px;
        left: 435px;
    }

    .vacancy-advantages__title.text--handwritten {
        font-size: 55px;
        line-height: 60px;
    }

    .vacancy-advantages__list {
        font-size: 24px;
        margin-top: 50px;
    }

    .vacancy-advantages__item {
        margin-top: 27px;
    }

    .vacancies-list .accordeon-item {
        padding: 0;
    }

    .vacancies-list .accordeon-item__title-icon {
        order: 2;
    }

    .vacancy-title {
        margin: 0;
        font-size: 36px;
    }

    .vacancy-button {
        display: none;
    }

    .vacancies-list .accordeon-item.accordeon-item--opened .accordeon-item__description,
    .vacancies-list .accordeon-item .accordeon-item__description {
        padding: 0;
        margin-left: 0;
    }

    .vacancy-requirements {
        margin: 0;
        margin-top: 24px;
    }

    .restaurant-images__slider {
        overflow: hidden;
    }

    .restaurant-images__slider .slide-active .restaurant-images__slider-item {
        width: 495px;
        height: 397px;
    }

    .restaurant-images__slider-item {
        width: 312px;
        height: 236px;
    }


    .restaurant-images__slider .slide {
        margin: 0 10px !important;
    }


    .restaurants-page__image, .restaurant-page__image {
        display: none;
    }

    .restaurant-page__holder {
        position: initial;
    }

    .restaurants-page__description, .restaurant-page__description {
        position: relative;
        align-self: flex-start;
        margin-top: 50px;
        width: 72%;
    }

    .restaurants-page__holder, .restaurant-page__holder {
        justify-content: flex-start;
    }

    .restaurants-page__description .button, .restaurant-page__description .button {
        width: calc(100% - 45px);
        margin-top: 40px;
        padding: 24px;
    }

    .restaurants-page__description .button span, .restaurant-page__description .button span {
        font-size: 23px;
    }

    .restaurant-page__slider {
        margin-top: 170px;
    }

    .restaurants-page--banner, .restaurant-page--banner {
        position: absolute;
        right: 0;
        top: 0;
        height: 866px;
        width: 426px;
        background-position: center;
        background-size: cover;
        border-radius: 15px 0 0 0;
        background-color: #5d5d5d;
        background-blend-mode: overlay;
        z-index: 1;
    }


    .page-restaurants .nav-phone {
        color: #fff;
    }

    .page-restaurants .nav-buttons__item > img {
        filter: invert(0);
    }

    .page-restaurants .cart-counter--count {
        color: #fff;
    }

    .page-restaurants .cart-counter--icon {
        border: 1.22727px solid #fff;
    }

    .page-vacancies, .page-success {
        background-image: url('../green_mob.svg') !important;
    }

    img.about__description--cherry {
        position: absolute;
        left: 276px;
        top: 100px;
    }

    img.about__vacancies-text--lists {
        top: -110px;
        left: 0px;
    }

    img.about__vacancies-text--broccoli {
        top: 520px;
        left: 352px;
    }


    .about__vacancies-text .button {
        width: initial;
    }

    .vacancies-form {
        flex-direction: column;
    }

    .vacancies-form__image img {
        max-width: 100%;
        object-fit: cover;
    }

    .vacancies-form__inner {
        padding: 70px 50px;
    }

    .vacancies-form__form {
        width: 100%;
    }

    .vacancies-form__title {
        text-align: center;
    }

    .vacancies-form__title .title {
        font-size: 45px;
    }

    .vacancy-modal {
        width: 100%;
        padding: 70px 40px;
        box-sizing: border-box;
    }

    .zone-modal__item-text--title {
        font-family: 'Raleway', sans-serif;
        font-size: 22px;
    }

    .zone-modal__item-text--address {
        font-size: 18px;
        opacity: 0.5;
    }

    .zone-modal__button {
        width: 75%;
    }

    .restaurants-page__buttons {
        flex-direction: column;
    }

    .restaurants-page__buttons .button + .button {
        margin-left: 0px !important;
    }

    .about-images__image img {
        width: 100%;
        object-fit: cover;
        height: 560px;
        object-position: top;
    }

    .personal__exit {
        right: 73px;
        top: -270px;
        z-index: 10000;
    }


    img.order-success-static--onion {
        position: absolute;
        left: 210px;
        top: -150px;
    }


    img.order-success-static--pickle {
        position: absolute;
        left: 410px;
        top: 230px;
    }

    img.order-success-static--petr2 {
        display: none;
    }

    img.order-success-static--petr1 {
        display: none;
    }

    .personal__order-payment, .personal__order-summary, .personal__order-products {
        width: 100%;
    }

    .catalog-text {
        padding: 0 120px;
        position: absolute;
        top: -330px;
        width: initial;
    }

    .container.catalog {
        position: relative;
    }

    .product-modal__modifiers {
        max-width: 100%;
    }

    .checkout__confirm-text span {
        font-size: 18px;
    }

    .checkout__agree-text a, .checkout__confirm-text a {
        font-size: 18px;
    }

    .checkout__sum.desktop {
        display: none !important;
    }

    .zone-dropdown-item {
        font-size: 22px;
        padding: 20px 10px
    }

    .zone-dropdown-value-text {
        font-size: 22px;
    }

    .zone-dropdown {
        padding: 18px 22px 18px 10px;
    }

    .page-restaurants .nav-phone a {
        color: #fff;
    }

    .product-card__body-footer_button .button span {
        font-weight: normal;
    }

    .catalog-buttons .button {
        height: 30px;
    }

    .catalog-buttons .button span {
        font-size: 22px;
    }

    .catalog-button--cart {
        display: flex;
        justify-content: space-between;
    }

    .catalog-button--cart span + span {
        opacity: 0.5;
    }

    .modal-header-clearfix {
        height: 155px;
        width: 100%;
        position: absolute;
        left: 0;
        padding: 0;
    }

    [data-rsbs-content] {
        position: relative;
    }

    [data-rsbs-content]:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100px;
        height: 7px;
        background: #4f4f4f;
        top: 30px;
        border-radius: 5px;
        opacity: 0.3;
    }

    .with-picture [data-rsbs-content]:before {
        background: #fff !important;
        opacity: 0.7;
    }

    .with-header [data-rsbs-content]:before {
        display: none;
    }

    .with-header [data-rsbs-header]:after {
        content: '';
        display: block !important;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100px;
        height: 7px;
        background: #4f4f4f;
        top: 30px;
        border-radius: 5px;
        opacity: 0.3;
    }

    .small-modal [data-rsbs-header] {
        margin-top: -30px;
    }

    .small-modal .modal-header-clearfix {
        height: 100px;
    }

    .action-modal-sheet__button {
        font-size: 22px;
    }

    .action-modal-sheet__button .button {
        padding: 18px 30px;
    }

    .specakciya {
        font-style: normal;
        font-weight: 900;
        font-size: 32px;
        line-height: 38px;
        text-transform: uppercase;
        color: #BF4A26;
    }

    .product-card__body-title .specakciya {
        font-size: 16px;
        margin-top: -10px;
    }

    .cart_product-card__info .specakciya {
        font-size: 24px;
    }

    .page.page-landing-rest {
        background-image: url('../green_mob.svg');
    }

    .zone-dropdown-subtitle {
        margin-top: 14px;
        font-size: 18px;
    }
}
