@import "ui/nav.css";
@import "ui/footer.css";
@import "ui/input.css";
@import "ui/dropdown.css";
@import "ui/animation.css";
@import "ui/parallax.css";
@import "ui/grid.css";
@import "ui/fonts.css";

html, body {
    margin: 0;
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #F8F7F7;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

body {
    overflow-x: hidden;
    color: #363538;
}

.main, .page {
    background-image: url('../green.svg');
    background-size: 100%;
    background-position: left top;
    background-repeat: no-repeat;
}

.page.page-restaurants {
    background-image: unset;
}

.page.page-landing-rest {
    background-image: url('../white.png'), url('../images/landings/burger.png'), url('../green.svg');
    background-size: 100%, 850px, 100%;
    background-position: left top, 85% -25px, left top;
    background-repeat: no-repeat, no-repeat, no-repeat;
}

.page.page-vacancies {
    background-image: url('../white.png'), url('../images/vacancies/banner.png'), url('../green.svg');
    background-size: 100%, 680px, 100%;
    background-position: left top, 90% 112px, left top;
    background-repeat: no-repeat, no-repeat, no-repeat;
}

.page.page-success {
    background-image: url('../white.png'), url('../images/success/image1.png'), url('../green.svg');
    background-size: 100%, 500px, 100%;
    background-position: left top, 57% 112px, left top;
    background-repeat: no-repeat, no-repeat, no-repeat;
}


/*.page.page-success {*/
/*background-image: url('../white.png'), url('../images/success/image1.png'), url('../images/success/image2.png'), url('../green.svg');*/
/*background-size: 100%, 364px, 399px, 100%;*/
/*background-position: left top, 38% 106px, 67% 263px, left top;*/
/*background-repeat: no-repeat, no-repeat, no-repeat;*/
/*}*/

:focus {
    outline: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-muted {
    margin: 10px 0;
    font-size: 12px !important;
    color: #666;
    font-weight: normal;
}

.text-muted > * {
    font-size: 12px !important;
    color: #666;
    font-weight: normal;
    padding: 0 !important;
    margin: 0 !important;
}

.text-muted--collapsed {
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-muted--collapsed > * {
    display: inline;
}

.text-muted--hide {
    right: 0;
    top: 15px;
    background: #f8f7f7;
    padding: 10px 0;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}

.text-muted--show {
    position: absolute;
    right: 0;
    top: 15px;
    background: #f8f7f7;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}

.text-muted--show:before {
    content: '...';
    margin-right: 5px;
    font-weight: normal;
    padding: 0;
}

button {
    border: none;
}

.pointer {
    cursor: pointer;
}

.clearfix {
    height: 132px;
}

.kimchiLandingPageFix + .clearfix {
    background-color: #fff;
}

.back {
    align-items: center;
    display: flex;
    cursor: pointer;
}

.back__icon {
    height: 50px;
    width: 18px;
    margin-right: 15px;
}

.back__icon img {
    width: 100%;
    height: 100%;
}

.back__text {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
}

.container {
    width: 1320px;
    margin: 0 auto;
}

.container-fluid {
    width: 100%;
    margin: 0 auto;
}

.container-pd {
    padding-left: 110px;
    box-sizing: border-box;
}

.text {
    font-size: 17px;
    line-height: 23px;
    color: #363538;

    font-feature-settings: 'pnum' on, 'lnum' on;
}

.text--faded {
    opacity: 0.5;
}

.color-black {
    color: #363538
}

.color-green {
    color: #88B04B
}

.color-white {
    color: #ffffff;
}

.reveal {
    animation: reveal-animation--one 0.3s ease;
}

.title {
    font-family: 'Raleway Black', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 55px;
    line-height: 65px;
    text-transform: uppercase;
}

.subtitle {
    font-size: 55px;
    line-height: 76px;
}

.text--handwritten {
    font-family: 'HitchHike', sans-serif;
}

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 30px;
    border-radius: 7px;
    cursor: pointer;
}

.button span {
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
}

.button + .button {
    margin-top: 15px;
}

.button-gray {
    background: #DDDDDD;
    color: #ffffff;
    text-decoration: none;
}


.button-green {
    background: #88B04B;
    color: #F8F7F7;
    text-decoration: none;
}

.button-white {
    background: #F8F7F7;
    color: #363538;
    text-decoration: none;
}

.button-disabled {
    opacity: 0.5;
}

.button-black {
    color: #F8F7F7;
    background: #363538;
    text-decoration: none;
}

.shadowed {
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.card {
    padding: 30px 40px;
    background: rgba(136, 176, 75, 0.2);
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
}

.card__title {
    cursor: pointer;
    font-weight: 900;
    font-size: 25px;
    line-height: 29px;
    margin-bottom: 15px;
}

.card__body {
    font-size: 17px;
    line-height: 23px;
}

.card__buttons {
    margin-top: 25px;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: -10;
    height: 100%;
    width: 100%;
}

.loader.loader-fullsize {
    width: 100vw;
    height: 100vh;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 3px;
    border: 3px solid #88B04B;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #88B04B transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.not-found {
    min-height: calc(100vh - 118px);
}

.not-found__image {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-left: 40px;
}

.not-found__text {
    font-weight: 900;
    font-size: 25px;
    line-height: 125%;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    font-family: 'Raleway Black', sans-serif;
    margin-top: -20px;
}

.not-found__button {
    width: 200px;
    margin: 0 auto;
    margin-top: 25px;
}

.bubble {
    position: fixed;
    background: #88b04b;
    border-radius: 50px;
    z-index: 1000;
}

.bubble-hide {
    right: 0 !important;
    top: 0 !important;;
    opacity: 0;
    width: 0;
    height: 0;
    transition: all 1.5s;
}

.bubble-active {
    opacity: 1;
    width: 45px;
    height: 45px;
}

.marker-window {
    display: flex;
}


.marker-window img {
    height: 80px;
}

.marker-window a {
    color: #333;
}

.restaurant-contact__phone.text:hover {
    transition: all .3s;
    color: #000;
    text-shadow: 0px 1px 1px;
}

.restaurant-contact__phone.text {
    transition: all .3s;
}

.pickup-type__item--value {
    white-space: nowrap;
}


.specakciya {
    font-family: "Raleway Black", sans-serif;
    margin-top: 2px;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #BF4A26;
}
