.contacts__restaurants-list {
    margin-top: 70px;
    display: flex;
}

.contacts__restaurants-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 48% 48%;
    grid-gap: 60px 22px;
}

.contacts__restaurants-map {
    min-width: 447px;
    min-height: 611px;
}

.contacts__restaurants-map iframe {
    border: none;
    height: calc(100% + 150px);
    width: 100%;
    margin-top: -55px;
    pointer-events: none;
}

.contacts__restaurants-map--holder {
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
}

.restaurant-contact {
    margin-right: 50px;
}

.restaurant-contact > div {
    margin-bottom: 10px;
}

.restaurant-contact__title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #363538;
    margin-bottom: 20px !important;
}
.restaurant-contact__link {
    margin-top: 20px;
}

.restaurant-contact__title a {
    text-decoration: unset;
    color: #363538;
}

.restaurant-contact__link a {
    color: #363538;
}

.contacts__feedback {
    margin-top: 215px;
    display: flex;
}

.contacts__feedback-testimonial {
    padding-top: 50px;
    min-width: 410px;
    width: 410px;
    margin-right: 150px;
}

.contacts__feedback-testimonial .title {
    margin-bottom: 20px;
}

.contacts__feedback-testimonial .text {
    margin-bottom: 30px;
}

.restaurant-contact__phone a {
    text-decoration: none;
    color: #363538;
}