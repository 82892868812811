.dropdown-value-icon {
    margin-left: 12px;
}

.sort-dropdown .dropdown-value-icon {
    margin-left: 3px;
}

.dropdown {
    position: relative;
}

.dropdown-value {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    cursor: pointer;
}

.dropdown-inner {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15), 0 7px 16px rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    position: absolute;
    right: 0;
    margin-top: 15px;
}

.dropdown-item {
    cursor: pointer;
    padding: 15px;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    align-items: center;
}

.dropdown-item--active .dropdown-text {
    font-weight: 900;
}

.dropdown-radio {
    margin-right: 10px;
}

.dropdown-inner--open {
    display: flex;
}

/* Zone dropdown*/

.zone-dropdown {
    position: relative;
    padding: 13px 10px;
    background: #FFFFFF;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    box-sizing: border-box;
    line-height: 10px;
}

.zone-dropdown-value {
    display: flex;
    align-items: center;
}

.zone-dropdown-value-text {
    font-size: 17px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    opacity: 0.5;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.zone-dropdown-value-icon--place {
    width: 70px;
}

.zone-dropdown-value-icon--caret {
    width: 85px;
    text-align: center;
}

.zone-dropdown-inner {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15), 0 7px 16px rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    position: absolute;
    left: 0;
    margin-top: 25px;
    z-index: 100;
}

.zone-dropdown-item {
    cursor: pointer;
    padding: 15px;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    align-items: center;
}

.zone-dropdown-item--active .zone-dropdown-text {
    font-weight: 900;
}

.zone-dropdown-text {
    line-height: initial;
}

.zone-dropdown-with_address .zone-dropdown-title {
    font-weight: bold;
}

.zone-dropdown-subtitle {
    margin-top: 14px;
}

.zone-dropdown-radio {
    margin-right: 10px;
}

.zone-dropdown-inner--open {
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

/* Sort drop */
.sort-dropdown {
    position: relative;
    padding: 18px 10px;
    background: #FFFFFF;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    box-sizing: border-box;
    line-height: 10px;
}

.sort-dropdown .dropdown-value {
    display: flex;
    align-items: center;
}

.sort-dropdown .dropdown-inner {
    z-index: 10;
    right: unset;
    left: 0;
    margin-top: 25px;
}

.sort-dropdown .dropdown-value-icon {
    width: 30px;
    height: 25px;
    text-align: center;
    margin-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sort-dropdown .dropdown-value-text {
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    color: #363538;
}

.delivery-time__selector {
    display: flex;
    margin-top: 20px;
    align-items: center;
}

.delivery-time__selector--label {
    font-size: 14px;
}

.delivery-time__selector--label div + div {
    margin-top: 10px;
}

.delivery-time__selector .dropdown-inner.dropdown-inner--open {
    overflow: auto;
    height: 230px;
    justify-content: flex-start;
    padding: 20px;
}

.delivery-time__selector .dropdown-inner.dropdown-inner--open::-webkit-scrollbar {
    width: 1px;
}

.delivery-time__selector--dropdown {
    margin-left: 30px;
}
