.testimonials .testimonials__slider-holder .slick-slide {
    width: 467px
}

.testimonials__description {
    min-width: 450px;
    margin-top: 10px;
}

.testimonials__description .text {
    margin-top: 20px;
}

.testimonials__slider-holder {
    width: 100%;
    margin-left: 110px;
}

.testimonials__card {
    width: 443px;
    background: #88B04B33;
    height: 332px;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 5px;
}

.testimonials__card-title_date {
    margin-top: 6px;
    font-size: 13px;
    line-height: 17px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    opacity: 0.5;
}

.testimonials__card-body {
    margin-top: 40px;
}

.testimonials__card-footer {
    margin-top: 20px;
}

.testimonials__card-body_title {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    margin-bottom: 20px;
}

.testimonials__card-body_summary {
    font-size: 13px;
    line-height: 17px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    opacity: 0.5;
}

.testimonials__card-heading, .testimonials__card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.testimonials__card-footer_link {
    font-size: 13px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #363538;
    opacity: 0.5;
}

.testimonials__card-body_summary {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 85px;
}

.testimonials__slider-arrows {
    display: flex;
    margin-top: 43px;
}

.testimonials__slider-arrows .slider-arrow {
    margin: 0 30px;
    cursor: pointer;
}

.testimonials__slider-arrows .slider-arrow + .slider-arrow {
    margin-left: 70px;
}

