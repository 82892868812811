
.main-banner .inner {
    margin: 0 auto;
    width: 1095px;
    display: flex;
}

.main-banner__col {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.main-banner__col.images {
    align-items: flex-end;
    max-height: 742px;
    max-width: 545px;
}

.main-banner__col + .main-banner__col {
    margin-left: 18px;
}

.main-banner__text {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
}

.main-banner__image {
    min-height: 552px;
}

.main-banner__text .subtitle {
    align-self: flex-end;
    margin: 0 45px 0;
}

.main-banner__card {
    max-width: 423px;
}

.main-banner__product-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

    min-height: 175px;
    position: relative;
}

.main-banner__product-row-item {
    width: 165px;
    transition: all .3s;
    position: absolute;
    cursor: pointer;
}

.main-banner__product-row-item + .main-banner__product-row-item {
    margin-left: 25px;
}

.main-banner__product-row-item img {
    max-width: 100%;
}


/**/


.main-banner__product-row-item.banner-first.active {
    width: 536px;
    z-index: 1;
}

.main-banner__product-row-item.banner-second.active {
    width: 536px;
    z-index: 1;
}

.main-banner__product-row-item.banner-third.active {
    width: 536px;
    z-index: 1;
}

.main-banner__product-row-item.banner-fourth.active {
    width: 536px;
    z-index: 1;
}

.main-banner-arrow {
    position: absolute;
    cursor: pointer;
}

.main-banner-arrow.main-banner-arrow--left {
    left: -50px;
    bottom: 120px;
}

.main-banner-arrow.main-banner-arrow--right {
    right: 65px;
    bottom: 120px;
}

.main-banner__col {
    position: relative;
}