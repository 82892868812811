.modal {
    position: fixed;
    width: 60%;
    background: #fff;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    margin: 0 auto;
    display: none;
    z-index: 22;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2), 4px 4px 4px rgba(255, 255, 255, 0.3);
}

.modal-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    background: #fff6;
    top: 0;
    z-index: 20;
    display: none;
    align-items: center;
    justify-content: center;
}

.modal-overlay--cart {
    background: #3336;
}

.modal-cross {
    position: absolute;
    right: 40px;
    top: 40px;
    cursor: pointer;
}

.modal-overlay--open {
    display: flex;
}

.modal--open {
    display: block;
    animation: reveal-with-opacity 0.5s ease;
}

@keyframes reveal-with-opacity {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes reveal-with-opacity {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Zone modal */
.zone-modal__inner {
    display: grid;
    grid-template-columns: 31% 31% 31%;
    grid-gap: 20px 20px;
    margin: 25px;
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15), 0 7px 16px rgba(0, 0, 0, 0.19);
    border-radius: 10px;
}

.zone-modal__item {
    display: flex;
    cursor: pointer;
}

.zone-modal {
    padding: 55px;
    max-width: 1100px;
    z-index: 1000;
}

.zone-modal__button {
    width: 200px;
    margin: 0 auto;
    text-align: center;
}

.zone-modal__title .title {
    font-size: 45px;
    line-height: 53px;
    text-align: center;
    text-transform: uppercase;
}

.zone-modal__item-text {
    margin-left: 10px;
}

.zone-modal__item-text--link a {
    color: #363538;
    font-size: 14px;
}

.zone-modal__item-text--address {
    font-size: 14px;
}

.zone-modal__item-text > div {
    margin-bottom: 10px;
}

.zone-modal__item-text--title {
    font-family: 'Raleway Black', sans-serif;
    font-size: 19px;
    line-height: 22px;
    color: #363538;
}

/* Product modal */

.product-modal {
    min-width: 1200px;
    padding: 0;
}

.product-modal.modal--open {
    display: flex;

}

.product-modal__selector {
    min-width: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-modal__inner {
    display: flex;
}

.product-modal__image {
    width: 532px;
    min-width: 532px;
    background-size: cover;
    background-position: center;
}

.product-modal__content {
    padding: 80px 55px;
    box-sizing: border-box;
    min-width: 393px;
}

.product-modal__title {
    font-family: "Raleway Black", sans-serif;
    font-size: 25px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    text-transform: uppercase;
}
.product-modal__toppings {
    margin-top: 32px;
}

.product-modal__modifiers {
    max-width: 390px;
    position: relative;
    margin-top: 25px;
}

.product-modal__toppings_list .slick-list {
    padding: 10px;
    margin: 0 -5px;
}

.product-modal__toppings_list .slick-slide {
    margin: 0 5px;
}

.product-modal__toppings_title {
    font-weight: 900;
    font-size: 20px;
    line-height: 18px;
    text-transform: uppercase;
    color: #363538;
    font-family: "Raleway Black", sans-serif;
}

.product-modal__toppings_list {
    margin-top: 16px;
    position: relative;
    width: 100%;
}

.product-modal__topping {
    width: 133px;
    max-height: 207px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
}

.product-modal__topping-image {
    width: 133px;
    min-height: 133px;
    background-position: center;
    background-size: cover;
}

.product-modal__topping-body {
    box-sizing: border-box;
    padding: 16px 12px 12px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    grid-gap: 10px;
}

.product-modal__topping-name {
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
}

.product-modal__topping-desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-modal__topping-weight {
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    text-align: right;
    color: #363538;
    opacity: 0.4;
}

.product-modal__topping-price {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #88B04B;
}


.product-modal__topping-active {
    background: #88B04B;
}

.product-modal__topping-active .product-modal__topping-body div {
    color: #fff !important;
    opacity: 1;
}

.product-modal__topping-blocked {
   cursor: not-allowed;
    opacity: 0.5;
}

.product-modal__description {
    font-size: 17px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    padding: 20px 0;
    max-height: 165px;
    overflow: hidden;
}

.product-modal__line {
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: rgba(54, 53, 56, 0.40);
    width: 100%;
}

.product-modal__weight, .product-modal__ingredients, .product-modal__nutrition {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    opacity: 0.4;
    margin-bottom: 10px;
}

.product-modal__price {
    margin-top: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
}

.product-modal__price .muted {
    font-weight: 400;
    font-size: 20px;
    line-height: 17px;
    color: #363538;
    opacity: 0.4;
    margin-right: 10px;
}
.product-modal__buy {
    margin-top: 30px;
}

.product-modal__warning {
    display: block;
    font-size: 14px;
    margin-top: 10px;
    color: firebrick;
}

@media (max-width: 768px) {
    .product-modal__warning {
        font-size: 20px;
    }
}


/* Auth modal */

#agreement-agree {
    height: 1px;
    width: 1px;
    top: 16px;
    left: 6px;
    position: absolute;
}

.agreement__confirm {
    display: flex;
}

.auth-modal__body-agreement {
    display: flex;
    margin-top: 45px;
}

.agreement__confirm-checkbox img {
    width: 20px;
}

.agreement__confirm-text {
    margin-left: 15px;
}

.agreement__confirm-text a {
    font-size: 14px;
    line-height: 23px;
    color: rgba(54, 53, 56, 0.50);
}

.agreement__confirm-checkbox {
    position: relative;
}

.auth-modal {
    width: 870px;
    padding: 0;
    height: 620px;
}

.auth-modal__inner {
    padding: 103px 113px 100px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
}

.auth-modal__heading {
    height: 26%;
}

.auth-modal__body {
    height: 70%;
}

.auth-modal__footer {
    height: 20%;
}

.auth-modal__title {
    font-size: 55px;
    line-height: 65px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Raleway Black', sans-serif;
}

.auth-modal__subtitle {
    text-align: center;
    margin-top: 22px;
    font-size: 17px;
}

.auth-modal__body {
    margin-top: 45px;
}

.auth-modal__body-input input {
    width: 100%;
    border: initial;
    border-bottom: 2px solid rgba(54, 53, 56, 0.41);
    height: 35px;
    font-size: 17px;
}

.auth-modal__body-input + .auth-modal__body-input {
    margin-top: 20px;
}

.auth-modal__footer {
    margin: 0 auto;
    width: 90%;
}
.auth-modal__footer-disclamer {
    font-weight: 900;
    font-size: 25px;
    line-height: 125%;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #92B759;
}

.countdown {
    font-size: 12px;
    margin-top: 10px;
}

.auth-modal__body-next {
    margin-top: 20px;
}

.auth-modal__body-next button {
    margin: 0 auto;
}

/*Action modal*/

.action-modal {
    min-width: 1095px;
    padding: 0;
}

.action-modal.modal--open {
    display: flex;
}

.action-modal__selector {
    min-width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-modal__inner {
    display: flex;
}

.action-modal__image {
    min-width: 532px;
    background-size: cover;
    background-position: center;
    min-height: 570px;
}

.action-modal__content {
    padding: 95px 5px 95px 60px;
    box-sizing: border-box;
    min-width: 393px;
}

.action-modal__title {
    font-family: "Raleway Black", sans-serif;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 45px;
}

.action-modal__description {
    font-size: 17px;
    line-height: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    padding: 20px 0;
    max-height: 165px;
    overflow: hidden;
}

.action-modal__line {
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: rgba(54, 53, 56, 0.40);
    width: 100%;
}


.action-modal__button {
    margin-top: 30px;
}

.action-modal__button .button {
    text-transform: uppercase;
    font-weight: bold;
}

.action-modal__disclamer {
    opacity: 0.4;
    font-size: 13px;
}

.cart-modal .modal-cross img {
    height: 15px;
}

.cart-modal .modal-cross {
    top: 35px;
}