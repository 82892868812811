
.product-cards-slider {
    width: 100vw;
    margin-top: 70px;
}

.product-card {
    background: #FFFFFF;
    box-shadow: 0 5.50472px 5.50472px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 423px;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: 5px;
}


.product-card--simple {
    display: flex;
    min-width: 49.5%;

    background: #FFFFFF;
    box-shadow: 0 5.50472px 5.50472px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.product-card--simple + .product-card--simple {
    margin-left: 15px;
}

.product-card + .product-card {
    margin-left: 25px;
}

.product-card__picture {
    height: 255px;
    position: relative;
    z-index: 2;
}

.product-card__picture-image {
    height: 100%;
    background-position: center;
    background-size: cover;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
}

.product-card__picture.shaded .product-card__picture-image {
    background-color: #363538;
    background-blend-mode: overlay;
}

.product-card__modifiers {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
}

.product-card__modifiers.show {
    display: flex;
    flex-wrap: wrap;
}

.product-card__modifier {
    width: 100%;
    flex: 1 1 50%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
}

.product-card__modifier:nth-child(2) {
    border-left: 1px solid #fff;
}

.product-card__modifier:nth-child(3) {
    border-top: 1px solid #fff;
}

.product-card__modifier:nth-child(4) {
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
}

.product-card__modifier .text {
    color: #fff;
    font-size: 26px;
    line-height: 50px;
    font-family: "HitchHike", sans-serif;
}

.product-card__disabled {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-family: "HitchHike", sans-serif;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
}

.product-card__body {
    padding: 20px;

}

.product-card__body-title {
    display: flex;
    justify-content: space-between;
    height: 60px;
    text-transform: uppercase;
    cursor: pointer;
}

.product-card__body-title .text span {
    font-size: 26px;
    font-weight: 900;
    letter-spacing: -0.7px;
    line-height: 30px;
    font-family: 'Raleway Black', sans-serif;
}

.product-card__body-title .icon {
    padding: 5px 10px;
}

.product-card__body-description {
    margin-top: 25px;
}

.product-card__body-description .text {
    opacity: 0.4;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 47px;
}

.product-card__body-footer {
    display: flex;
    margin-top: 20px;
    align-items: flex-end;
}

.product-card__body-footer_weight {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 30px;
    color: #363538;
    opacity: 0.4;
}

.product-card__body-footer_button {
    min-width: 100px;
    max-width: 100px;
}

.product-card__body-footer_button .button span {
    font-size: 55px;
}

.product-card__body-footer_price {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    padding: 0 25px;
}

.product-modal-oldprice {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-decoration-line: line-through;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #363538;
    margin-right: 16px;
}

.product-card__body-footer-oldprice {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-decoration-line: line-through;
    font-feature-settings: 'pnum' on, 'lnum' on;

    color: #9E9CA2;
}

.product-card__body-footer_priceblock {
    display: flex;
    grid-gap: 12px;
    align-items: center;
}

.hits-link {
    margin-right: 40px;
}

.hits-link .arrow {
    margin-left: 15px;
}

.product-card--small.product-card {
    width: 311px;
    border-radius: 5px;
    margin: 0;
}

.product-card--small .product-card__picture-image {
    border-radius: 5px 5px 0 0;
}

.product-card--small .product-card__picture {
    height: 187px;
}

.product-card--small .product-card__body-title {
    height: 70px;
}

.product-card--small .product-card__body-title .text span {
    font-size: 19px;
    font-family: "Raleway Black", sans-serif;
    line-height: 125%;
    color: #363538;
}

.product-card--small .product-card__body-title .icon {
    padding: 4px;

}

.product-card--small .product-card__body-title .icon img {
    height: 22px;
}

.product-card--small .product-card__body-description {
    margin-top: unset;
}

.product-card--small .product-card__body-footer {
    margin-top: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.product-card--small .product-card__body-footer_weight {
    line-height: 30px;
}

.product-card--small .product-card__body-footer_price {
    padding: 0;
    line-height: 45px;
}

.product-modal__modifiers .dropdown-value {
    display: flex;
    justify-content: space-between;
}

.product-modal__modifiers .dropdown {
    margin: 20px 0;
    border: 1px solid #afaeaf;
    border-radius: 3px;
    padding: 15px;
}

.product-modal__modifiers .dropdown-inner--open {
    z-index: 10;
    left: 0;
    top: 40px;
}


.product-card--simple .product-card__picture {
    width: 310px;
    height: 284px;
    border-radius: 0;
    min-width: 310px;
}

.product-card--simple .product-card__picture-image {
    border-radius: 10px 0 0 10px;
}

.product-card--simple .product-card__body {
    width: 100%;
    padding: 45px 20px;
}

.product-card--simple .product-card__body-footer {
    margin-top: 0px;
    flex-direction: column;
    align-items: flex-start;
}

.product-card--simple .product-card__body-footer_label {
    margin-bottom: 5px;
}

.product-card--simple .product-card__body-footer_price {
    padding: 0;
}

.product-card--simple .product-card__body-footer_button {
    margin-top: 30px;
    max-width: initial;
    min-width: 180px;
}

@media (max-width: 768px) {
    .product-card__body-footer-oldprice {
        font-size: 18px;
    }

    .product-modal-oldprice {
        color: #A0A0A0;
    }
}